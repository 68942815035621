import React, { useState, useContext, useEffect } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { AuthContext } from "../context/AuthContext";
import { UserContext } from "../context/UserContext";
import "../Styles/dashboard8.css";
import { useNavigate } from "react-router-dom";
import {
  clientApiUrl,
  taskApiUrl,
  notificationApiUrl,
  userApiUrl,
} from "../utils/GetUrl";
export default function AccessibleTable() {
  const [empData, setEmpData] = useState([]);
  const [selectedDate, setSelectedDate] = useState(
    new Date().toISOString().split("T")[0]
  );
  const { token, userData } = useContext(AuthContext);
  const { teamsData, usersList } = useContext(UserContext);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [viewForce, setViewForce] = useState({});
  const [weeklyReport, setWeeklyReport] = useState({});
  const navigate = useNavigate();
  useEffect(() => {
    if (selectedDate) {
      const currentDate = new Date(selectedDate);
      const firstDayOfWeek = currentDate.getDate() - currentDate.getDay() + 1;
      const lastDayOfWeek = firstDayOfWeek + 5;

      const start = new Date(currentDate.setDate(firstDayOfWeek));
      const end = new Date(currentDate.setDate(lastDayOfWeek));

      const formatDate = (date) => date.toISOString().split("T")[0];

      setStartDate(formatDate(start));
      setEndDate(formatDate(end));
    }
  }, [selectedDate]);

  function arrayToString(emidString) {
    // Remove single quotes from the individual EMIDs
    const emidWithoutQuotes = emidString.replace(/'/g, "");

    // Enclose the whole string in single quotes
    return `${emidWithoutQuotes}`;
  }

  const fetchViewForce = async (EMIds) => {
    console.error('This this')
    console.log(EMIds)
    if (!EMIds) {
      return;
    }
    try {

      const response = await fetch(
        `${taskApiUrl}/GetDataByDate?StartDate=${startDate}&EndDate=${endDate}&EMId=${arrayToString(EMIds)}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response?.status === 401) {
        navigate("/");
      }
      if (response.ok) {
        const data = await response.json();
        return data;
      }
    } catch (error) {
      //console.log(error);
    }
    return [];
  };

  const fetchWeekly = async (EMIds) => {
    //console.log(EMIds);
    if (!EMIds) {
      return;
    }
    try {
      const response = await fetch(
        `${taskApiUrl}/GetAllTaskClientWiseReport?DateRange1=${startDate}&DateRange2=${endDate}&AssginedToEmids=${EMIds}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = await response.json();
      return data;
    } catch (e) {
      //console.log(e);
    }
    return [];
  };

  useEffect(() => {
    if (teamsData[0] && usersList) {
      //console.log(teamsData[0]);

      teamsData[0]
        .filter((team) => team?.status === true && team?.type === "T")
        .forEach(async (team) => {
          const teamEmployees = usersList
            .filter((user) => user?.STM_Team == team.id)
            .map((user) => `'${user.EM_ID}'`)
            .join(",");

          const vfData = await fetchViewForce(teamEmployees);
          console.log(teamEmployees, vfData)
          const weeklyData = await fetchWeekly(teamEmployees);

          setViewForce((prev) => ({ ...prev, [team.id]: vfData }));
          setWeeklyReport((prev) => ({ ...prev, [team.id]: weeklyData }));
        });
    }
  }, [teamsData, usersList, startDate, endDate]);

  const rows = teamsData[0]
    ?.filter((team) => team?.status === true && team?.type === "T" && !(userData?.[0]?.DR_Name == "Consultant" && team?.id != userData?.[0]?.STM_Team))
    ?.map((team) => {
      const teamViewForce = viewForce[team.id] || [];
      const teamWeeklyReport = weeklyReport[team.id] || [];

      const vfTime = teamViewForce.reduce((total, vf) => {
        const time = parseFloat(vf.registeredTime);
        return total + (isNaN(time) ? 0 : time);
      }, 0);

      const actualTime = teamWeeklyReport.reduce((total, wr) => {
        const time = parseFloat(wr.TotalTime);
        return total + (isNaN(time) ? 0 : time);
      }, 0);

      return {
        name: team.name,
        vft: isNaN(vfTime) ? 0 : vfTime,
        at: isNaN(actualTime) ? 0 : actualTime,
        d: isNaN(actualTime - vfTime) ? 0 : actualTime - vfTime,
      };
    });

    const handleClick = () => {
      navigate("/WeeklyReview"); 
    };

  return (
    <div className="lastTable-dashboard">
      <div className="last-dash-head">
        <div className="last-dash-heading1" onClick={handleClick} style={{ cursor: "pointer" }}>WEEKLY TIME REVIEW</div>
        <div className="last-dash-drop">
          <input
            type="date"
            className="date-design-weekly"
            value={selectedDate}
            onChange={(e) => setSelectedDate(e.target.value)}
            label="Select Date"
          />
        </div>
      </div>
      <div className="last-dash-subhead">
        <div>Budgeted and actual value comparison</div>
      </div>
      <TableContainer
        component={Paper}
        sx={{ maxHeight: 302, overflow: "auto" }}
      >
        <Table sx={{ minWidth: 650 }} aria-label="caption table">
          <TableHead>
            <TableRow
              className="t-r-head-lg"
              sx={{
                position: "sticky",
                top: 0,
                backgroundColor: "white",
                zIndex: 1,
              }}
            >
              <TableCell></TableCell>
              <TableCell align="right">REPORTED TIME</TableCell>
              <TableCell align="right">ACTUAL TIME</TableCell>
              <TableCell align="right">DIFFERENCE</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows?.map((row, index) => (
              <TableRow
                key={row.name}
                className={index % 2 === 0 ? "odd-row" : "even-row"}
              >
                <TableCell component="th" scope="row">
                  {row.name}
                </TableCell>
                <TableCell align="right">{row.vft?.toFixed(2)}</TableCell>
                <TableCell align="right">{row.at?.toFixed(2)}</TableCell>
                <TableCell
                  align="right"
                  className={index % 2 === 0 ? "odd-cell" : "even-cell"}
                >
                  {row.d?.toFixed(2)}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
