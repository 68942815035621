import React, { useContext, useRef, useState, useEffect } from "react";
import NavSub from "../components/NavSub";
import Footerweek from "../components/Footerweek";
import Weeklytimesheet2 from "../components/Weeklytimesheet2";
import "../Styles/Mergenew.css";
import "../Styles/Weekly.css";
import "../Styles/ViewForceUser.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import {
  clientApiUrl,
  taskApiUrl,
  notificationApiUrl,
  userApiUrl,
} from "../utils/GetUrl";
import {
  CaretDown,
  ClockCounterClockwise,
  Download,
  XCircle,
} from "@phosphor-icons/react";
import {
  Button,
  CircularProgress,
  Divider,
  Menu,
  MenuItem,
} from "@mui/material";
import { AuthContext } from "../context/AuthContext";
import * as XLSX from "xlsx";
import ToastComponent from "../components/ToastComponent";
import { UserContext } from "../context/UserContext";
import { useNotifications } from "../context/NotificationContext";
import ViewForceWeeklySupUser from "../components/ViewForceWeeklySupUser";
import { ConsoleLogger } from "@microsoft/signalr/dist/esm/Utils";
import Slider from "react-slick";
function ViewForceSupUser() {
  const { userData } = useContext(AuthContext);
  const currentYear = new Date().getFullYear();
  const currentMonth = new Date().getMonth() + 1;
  const [teamsData, setTeamsData] = useState([]);
  const { token, logout } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const user = userData?.[0];
  const toastRef = useRef();
  const [selectedYear, setSelectedYear] = useState(currentYear);
  const [selectedMonth, setSelectedMonth] = useState(currentMonth);
  const [data, setData] = useState([]);
  const [uploadedData, setUploadedData] = useState(null);
  const [latestData, setLatestData] = useState();
  const [anchorEl, setAnchorEl] = useState(null);
  const [historyDatamodel, setHistoryDatamodel] = useState([]);
  const { teamNames, usersList } = useContext(UserContext);
  const [userMap, setUserMap] = useState({});
  const [employeeData, setEmployeeData] = useState([]);
  const [selectedTeam, setSelectedTeam] = useState("");
  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);
  const {
    notificationsForViewForceData,
    setNotificationsForViewForceData,
    connectionIdViewForceData,
  } = useContext(AuthContext);
  const userRole = "Manager";
  const months = [
    'January', 'February', 'March', 'April', 'May', 'June',
        'July', 'August', 'September', 'October', 'November', 'December'
  ];
  const handleTeamChange = (event) => {
    const value = event.target.value;
    if (value === "none") {
      setSelectedTeam(""); // Clear the selection
    } else {
      setSelectedTeam(value); // Set the selected team
    }
    fetchEmployeeData();
  };
  useEffect(() => {
    const fetchTeams = async () => {
      try {
        const response = await fetch(`${userApiUrl}/GetTeams?`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        if (response.status === 401) {
          toastRef.current.showToast(
            "Unauthorised access. Please login again.",
            "error"
          );
          await logout();
          return;
        }
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        const data = await response.json();
        const groupedTeams = data.reduce((acc, team) => {
          const id = team.SuperTeamId;
          if (!acc[id]) {
            acc[id] = [];
          }
          acc[id].push(team);
          return acc;
        }, {});

        const filtered = Object.values(groupedTeams);
        setTeamsData(filtered);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    if (token) {
      fetchTeams();
    }
  }, [token]);
  const fetchEmployeeData = async () => {
    try {
      const response = await fetch(`${userApiUrl}/GetAllEmp`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.status === 401) {
        toastRef.current.showToast(
          "Unauthorised access. Please login again.",
          "error"
        );
        await logout();
        return;
      }
      const data = await response.json();
      setEmployeeData(data);
    } catch (error) {
      console.error("Error fetching employee data:", error);
    }
  };

  useEffect(() => {
    fetchEmployeeData(); // Fetch employee data on component mount
  }, []);

  useEffect(() => {
    const map = userData?.reduce((acc, user) => {
      acc[user.EM_ID] = user;
      return acc;
    }, {});
    setUserMap(map);
  }, [userData]);

  // const getEmployeeIds = () => {
  //   return employeeData
  //     .filter(
  //       (emp) =>
  //         !selectedTeam || parseFloat(emp.STM_Team) === parseFloat(selectedTeam)
  //     )
  //     .map((emp) => `'${emp.EM_ID}'`)
  //     .join(",");
  // };
  const getEmployeeIds = () => {
    // Generate the comma-separated list of IDs
    const ids = employeeData
      .filter(
        (emp) =>
          !selectedTeam || parseFloat(emp.STM_Team) === parseFloat(selectedTeam)
      )
      .map((emp) => emp.EM_ID) // Get each ID without quotes
      .join(","); // Join with commas
  
    // Enclose the entire list in single quotes
    return `${ids}`;
  };
  

  const getSubteams = () => {
    const subteams = {};
    employeeData
      .filter(
        (emp) =>
          !selectedTeam || parseFloat(emp.STM_Team) === parseFloat(selectedTeam)
      )
      .forEach((emp) => {
        const subteamId = emp.STM_Subteam;
        const subteamName =
          teamNames.find((team) => team.id.toString() === subteamId)?.name ||
          "Unknown";
        if (!subteams[subteamName]) {
          subteams[subteamName] = [];
        }
        subteams[subteamName].push(emp);
      });
    return subteams;
  };

  const subteams = getSubteams();
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);
  const calendarWrapperRef = useRef(null);
  const handleYearChange = (event) => {
    const year = parseInt(event.target.value);
    setSelectedYear(year);
    setIsCalendarOpen(false);
  };

  // const handleMonthChange = (event) => {
  //   setSelectedMonth(parseInt(event.target.value));
  //   setIsCalendarOpen(false);
  // };
  // const toggleCalendar = () => {
  //   setIsCalendarOpen(!isCalendarOpen);
  // };
  const handlePreviousYear = () => {
    setSelectedYear(selectedYear - 1);
    
  };

  const handleNextYear = () => {
    setSelectedYear(selectedYear + 1);
  };

  const handleMonthChange = (month) => {
    setSelectedMonth(month);
    setIsCalendarOpen(false); 
  };

  const toggleCalendar = () => {
    setIsCalendarOpen((prev) => !prev); 
  };
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (calendarWrapperRef.current && !calendarWrapperRef.current.contains(event.target)) {
        setIsCalendarOpen(false);
      }
    };

    if (isCalendarOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isCalendarOpen]);
  const years = [];
  for (let year = currentYear; year >= 2000; year--) {
    years.push(year.toString());
  }
  const fetchHistoryDatamodel = async () => {
    try {
      const response = await fetch(`${taskApiUrl}/GetViewForceDataHistory`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.status === 401) {
        toastRef.current.showToast(
          "Unauthorised access. Please login again.",
          "error"
        );
        await logout();
        return;
      }
      if (!response.ok) {
        throw new Error("Failed to fetch budget history data");
      }
      const data = await response.json();
      setHistoryDatamodel(data);
    } catch (error) {
      console.error("Error fetching budget data:", error);
    }
  };
  useEffect(() => {
    fetchHistoryDatamodel();
  }, []);
  const fetchData = async (year, month, team) => {
    try {
      setLoading(true);
      const startDate = `${year}-${month.toString().padStart(2, "0")}-01`;
      const endDate = new Date(year, month, 0);
      const endDateString = `${year}-${month
        .toString()
        .padStart(2, "0")}-${endDate.getDate()}`;
      const emIds = team ? getEmployeeIds() : "";
      console.log(emIds );
      let url = `${taskApiUrl}/GetDataByDate?StartDate=${startDate}&EndDate=${endDateString}`;
      if (team && emIds) {
        url += `&EMId=${emIds}`;
      }
      
  
      const response = await fetch(url, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
  
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
  
      if (response.status === 401) {
        toastRef.current.showToast(
          "Unauthorized access. Please log in again.",
          "error"
        );
        await logout();
        return;
      }
  
      const json = await response.json();
      setData(
        json.map((item) => ({
          projectTaskName: item.projectTaskName || "",
          accountName: item.accountName || "",
          org_Number: item.org_Number || "",
          costCenter: item.costCenter || "",
          activity: item.activity || "",
          fullName: item.fullName || "",
          timeId: item.timeId || "",
          weekNumber: item.weekNumber?.toString() || "",
          dateIncurred: item.dateIncurred || "",
          registeredTime: item.registeredTime?.toString() || "",
          billableTime: item.billableTime?.toString() || "",
          mainPriceAgreement: item.mainPriceAgreement || "",
          activityPriceAgreement: item.activityPriceAgreement || "",
          mainActivity: item.mainActivity || "",
          description: item.description || "",
          status: item.status || "",
          label14: calculateControlCheck(item),
          label15: checkSTR(item.activity, item.mainPriceAgreement),
          label17: check10Hrs(item.registeredTime, item.billableTime),
          label18: checkHourlyRate(
            item.mainPriceAgreement,
            item.activity,
            item.registeredTime,
            item.billableTime
          ),
          label19: checkRTBT(item.registeredTime, item.billableTime),
          label20: checkMainActivity(item.mainActivity),
          label21: checkComments(item.activity, item.description),
          label22: checkApproval(item.dateIncurred, item.status),
          label16: checkAdhoc(item.projectTaskName, item.accountName),
        }))
      );
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };
  

  const fetchLatestData = async () => {
    try {
      setLoading(true);
      const response = await fetch(`${taskApiUrl}/GetLatestViewForceData`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.status === 401) {
        toastRef.current.showToast(
          "Unauthorized access. Please login again.",
          "error"
        );
        try {
          await logout();
        } catch (logoutError) {
          console.error("Error during logout:", logoutError);
        }
        return;
      }

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const json = await response.json();
      setLatestData(json);
      setUploadedData(json.data);
    } catch (error) {
      console.error("Error fetching latest data:", error.message);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    const currentYear = new Date().getFullYear();
    const currentMonth = new Date().getMonth() + 1; // getMonth() returns 0-11, so add 1

    fetchData(currentYear, currentMonth, selectedTeam); // Fetch data for the current year and month
  }, [selectedTeam]); // Fetch again if selectedTeam changes

  useEffect(() => {
    if (selectedYear || selectedMonth || selectedTeam) {
      fetchData(selectedYear, selectedMonth, selectedTeam);
    }
  }, [selectedYear, selectedMonth, selectedTeam]);

  const calculateControlCheck = (item) => {
    const isOk =
      checkSTR(item.activity, item.mainPriceAgreement) === "Ok" &&
      check10Hrs(item.registeredTime, item.billableTime) === "Ok" &&
      checkHourlyRate(
        item.activityPriceAgreement,
        item.activity,
        item.registeredTime,
        item.billableTime
      ) === "Ok" &&
      checkRTBT(item.registeredTime, item.billableTime) === "Ok" &&
      checkMainActivity(item.mainActivity) === "Ok" &&
      checkComments(item.activity, item.description) === "Ok" &&
      checkApproval(item.dateIncurred, item.status) === "Ok" &&
      checkAdhoc(item.projectTaskName, item.accountName) === "Ok";
    return isOk ? "Ok" : "Check";
  };

  const checkSTR = (activity, mainPriceAgreement) => {
    const targetActivity = "0801 Financial statement and tax report";
    const targetPriceAgreement = "Fixed Price";
    return activity === targetActivity &&
      mainPriceAgreement !== targetPriceAgreement
      ? "Check"
      : "Ok";
  };

  const check10Hrs = (registeredTime, billableTime) => {
    if (
      registeredTime > 10 ||
      billableTime > 10 ||
      registeredTime < 0 ||
      billableTime < 0
    ) {
      return "Check";
    } else {
      return "Ok";
    }
  };

  const checkHourlyRate = (
    mainPriceAgreement,
    activity,
    registeredTime,
    billableTime
  ) => {
    const registeredTimeNum = Number(registeredTime);
    const billableTimeNum = Number(billableTime);
  
    const isRegisteredTimeValid = registeredTimeNum === 0;
    const isBillableTimeValid = billableTimeNum !== 0;
  
    if (
      mainPriceAgreement === "Hourly Rate" &&                               
      !activity.includes("0801 Financial statement and tax report") &&      
      isRegisteredTimeValid &&                                              
      isBillableTimeValid                                                 
    ) {
      return "Check";
    } else {
      return "Ok";
    }
  };
    
  const checkRTBT = (registeredTime, billableTime) => {
    const rt = Number(registeredTime);
    const bt = Number(billableTime);
          if (rt === 0 && bt !== 0) {
      return "Check";
    } else {
      return "Ok";
    }
  };
  
  
  
  
  

  const checkMainActivity = (mainActivity) => {
    if (!mainActivity) {
      return "Ok";
    } else if (mainActivity === "Outsourcing" || mainActivity === "Internal") {
      return "Ok";
    } else {
      return "Check";
    }
  };

  const checkComments = (activity, description) => {
    if (
      (activity === "0406 Consulting - Financial" &&
        description?.trim() === "") ||
      (activity === "0501 Consulting - Administration" &&
        description?.trim() === "")
    ) {
      return "Check";
    } else {
      return "Ok";
    }
  };
  const checkApproval = (dateIncurred, status) => {
    if (!dateIncurred) {
      return "Ok";
    } else if (
      dateIncurred &&
      (status === "Approved" || status === "Invoiced")
    ) {
      return "Ok";
    } else {
      return "Check";
    }
  };

  const checkAdhoc = (projectTaskName, accountName) => {
    if (projectTaskName && accountName) {
      return "Ok";
    }
    return "Check";
  };
  const handleDownloadSample = () => {
    // Path to your Excel file in the public folder
    const fileUrl = `${process.env.PUBLIC_URL}/ViewForceSampleFile.xlsx`;

    // Create a temporary anchor element
    const link = document.createElement('a');
    link.href = fileUrl;
    link.setAttribute('download', 'viewForce.xlsx'); // Set the file name

    // Append to the body
    document.body.appendChild(link);
    link.click(); // Trigger the download
    link.remove(); // Remove the link after download
  };
  const handleExportClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleExportClose = () => {
    setAnchorEl(null);
  };
  const handleExportFiles = () => {
    let exportData = [];

    if (!selectedYear && !selectedMonth) {
      exportData = [...(latestData || []), ...(data || [])];
    } else {
      exportData = data || [];
    }

    const filteredData = exportData.map((item) => {
      if (item.details) {
        return {
          "PROJECT NAME": item.projectTaskName || "",
          "ACCOUNT NAME": item.accountName || "",
          "ORG NUMBER": item.org_Number || "",
          "COST CENTRE": item.details.label1 || "", // Use details.label1 if available
          ACTIVITY: item.details.label2 || "", // Use details.label2 if available
          "FULL NAME": item.details.label3 || "", // Use details.label3 if available
          "TIME ID": item.details.label4 || "", // Use details.label4 if available
          "WEEK NO": item.details.label5 || "", // Use details.label5 if available
          "DATE INCURRED": item.details.label6 || "", // Use details.label6 if available
          "REGISTERED TIME": item.details.label7 || "", // Use details.label7 if available
          "BILLABLE TIME": item.details.label8 || "", // Use details.label8 if available
          "MAIN PRICE AGREEMENT": item.details.label9 || "", // Use details.label9 if available
          "ACTIVITY PRICE AGREEMENT": item.details.label10 || "", // Use details.label10 if available
          "MAIN ACTIVITY": item.details.label11 || "", // Use details.label11 if available
          DESCRIPTION: item.details.label12 || "", // Use details.label12 if available
          STATUS: item.details.label13 || "", // Use details.label13 if available
          "CONTROL CHECK": item.details.label14 || "", // Use details.label14 if available
          "STR CHECK 1": item.details.label15 || "", // Use details.label15 if available
          "10 HRS CHECK 2": item.details.label16 || "", // Use details.label16 if available
          "HOURLY RATE CHECK 3": item.details.label17 || "", // Use details.label17 if available
          "RT- BT CHECK 4": item.details.label18 || "", // Use details.label18 if available
          "MAIN ACTIVITY CHECK 5": item.details.label19 || "", // Use details.label19 if available
          "COMMENTS CHECK 6": item.details.label20 || "", // Use details.label20 if available
          "APPROVAL CHECK 7": item.details.label21 || "", // Use details.label21 if available
          "ADHOC CHECK 8": item.details.label22 || "", // Use details.label22 if available
        };
      } else {
        // Handle case where details are not available
        return {
          "PROJECT NAME": item.projectTaskName || "",
          "ACCOUNT NAME": item.accountName || "",
          "ORG NUMBER": item.org_Number || "",
          "COST CENTRE": item.costCenter || "",
          ACTIVITY: item.activity || "",
          "FULL NAME": item.fullName || "",
          "TIME ID": item.timeId || "",
          "WEEK NO": item.weekNumber || "",
          "DATE INCURRED": item.dateIncurred || "",
          "REGISTERED TIME":
            item.registeredTime !== null && item.registeredTime !== undefined
              ? item.registeredTime
              : 0,
          "BILLABLE TIME":
            item.billableTime !== null && item.billableTime !== undefined
              ? item.billableTime
              : 0,
          "MAIN PRICE AGREEMENT": item.mainPriceAgreement || "",
          "ACTIVITY PRICE AGREEMENT": item.activityPriceAgreement || "",
          "MAIN ACTIVITY": item.mainActivity || "",
          DESCRIPTION: item.description || "",
          STATUS: item.status || "",
          "CONTROL CHECK": calculateControlCheck(item) || "",
          "STR CHECK 1": checkSTR(item.activity, item.mainPriceAgreement) || "",
          "10 HRS CHECK 2":
            check10Hrs(item.registeredTime, item.billableTime) || "",
          "HOURLY RATE CHECK 3":
            checkHourlyRate(
              item.mainPriceAgreement,
              item.activity,
              item.registeredTime,
              item.billableTime
            ) || "",
          "RT- BT CHECK 4":
            checkRTBT(item.registeredTime, item.billableTime) || "",
          "MAIN ACTIVITY CHECK 5": checkMainActivity(item.mainActivity) || "",
          "COMMENTS CHECK 6":
            checkComments(item.activity, item.description) || "",
          "APPROVAL CHECK 7":
            checkApproval(item.dateIncurred, item.status) || "",
          "ADHOC CHECK 8": checkAdhoc(item.projectTaskName, item.accountName),
        };
      }
    });

    if (filteredData.length === 0) {
      toastRef.current.showToast("No data available to export", "error");
      return;
    }

    const uniqueFileName = `viewforce.xlsx`;

    const workbook = XLSX.utils.book_new();
    const sheet = XLSX.utils.json_to_sheet(filteredData);

    XLSX.utils.book_append_sheet(workbook, sheet, "viewforce");

    try {
      XLSX.writeFile(workbook, uniqueFileName);
      toastRef.current.showToast("Exported successfully", "success");
    } catch (error) {
      console.error("Error exporting data:", error);
      toastRef.current.showToast("Error exporting data", "error");
    }
  };
  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  };
  const { postNotification, fetchNotifications } = useNotifications();
  const [isModalOpenError, setIsModalOpenError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const handleCloseErrorModal = () => {
    setIsModalOpenError(false); // This will close the modal
  };
  const handleFileUpload = async (event) => {
    const file = event.target.files[0];
    if (!file) {
      toastRef.current.showToast("No file selected", "error");
      setLoading(false);
      return;
    }

    if (
      file.type !==
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    ) {
      toastRef.current.showToast(
        "Please select an Excel file (XLSX format)",
        "error"
      );
      setLoading(false);
      return;
    }

    setLoading(true);
    try {
      const data = await file.arrayBuffer();
      const workbook = XLSX.read(data);
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];
      const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

      if (jsonData.length === 0 || jsonData[0].length === 0) {
        toastRef.current.showToast("No data found in the file", "error");
        setLoading(false);
        return;
      }

      const requiredColumns = [
        "Project Task: Project Task Name",
        "Project Task: Project: Account: Account Name",
        "Project Task: Project: Account: Org. Number",
        "Project Task: Project: Account: Cost Center",
        "Activity",
        "Full Name",
        "Time Id",
        "Week Number",
        "Date Incurred",
        "Registered Time",
        "Billable Time",
        "Main Price Agreement",
        "Activity Price Agreement",
        "Main Activity",
        "Description",
        "Status",
      ];

      const normalizeColumnName = (name) =>
        typeof name === "string" ? name.trim().toUpperCase() : "";

      const actualColumns = jsonData[0].map(normalizeColumnName);
      const missingColumns = requiredColumns
        .map(normalizeColumnName)
        .filter((col) => !actualColumns.includes(col));

      if (missingColumns.length > 0) {
        toastRef.current.showToast(
          `Missing required columns: ${missingColumns.join(", ")}`,
          "error"
        );
        setLoading(false);
        return;
      }

      const uniqueFileName = `u_${Date.now()}.xlsx`;
      const formData = new FormData();
      formData.append("Folder", "ViewForceData");
      formData.append("FileNameWithExtension", uniqueFileName);
      formData.append("Remarks", "H");
      formData.append(
        "SignalRConnectionId",
        connectionIdViewForceData?.connection?.connectionId || ""
      );
      formData.append("RequestDate", formatDate(new Date()));
      formData.append("RequestedBy", user?.EM_ID || "Unknown");
      formData.append("Attachment", file);
      formData.append("HubName", "viewForceDataHub");

      const uploadResponse = await fetch(`${taskApiUrl}/Uploadmedia`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formData,
      });

      if (uploadResponse.status === 401) {
        toastRef.current.showToast(
          "Unauthorized access. Please login again.",
          "error"
        );
        await logout();
        return;
      }

      const uploadResult = await uploadResponse.text();
      if (uploadResponse.ok) {
        if (
          uploadResult.includes(
            "Event trigger and file upload success and wait for process"
          )
        ) {
          // toastRef.current.showToast(
          //   "File upload successful. Waiting for processing.",
          //   "success"
          // );
          // await postNotification(
          //   "File upload completed successfully View Force.",
          //   usersList
          // );
        } else {
          // toastRef.current.showToast(`${uploadResult}`, "success");
          // await postNotification(`${uploadResult}`, "success", usersList);
        }
      } else {
        toastRef.current.showToast(
          `File upload failed: ${uploadResult}`,
          "error"
        );
      }
      await fetchNotifications();
    } catch (error) {
      console.error("Error during file upload:", error);
      toastRef.current.showToast("File upload failed", "error");
    } finally {
      // setLoading(false);
    }
  };
  useEffect(() => {
    const lastNotification =
      notificationsForViewForceData[notificationsForViewForceData.length - 1];
    if (!lastNotification) return;
    if (
      lastNotification === "File data uploaded" ||
      lastNotification === "File upload successful" ||
      lastNotification === "File prcoessed success"||
      lastNotification === "event triggered and file upload success and wait for process" 
    ) {
      toastRef.current.showToast("File processed successfully", "success");
      fetchData(currentYear, currentMonth);
    } 
   else{
    setErrorMessage(lastNotification); 
    setIsModalOpenError(true); 
   }
   setLoading(false);
    return () => {
      setNotificationsForViewForceData([]);
    };
  }, [notificationsForViewForceData]);
  
  const downloadFile = async (blobref) => {
    try {
      const v = blobref.replace(".xlsx", "");

      const response = await fetch(
        `${taskApiUrl}/GetDownloadViewForceFile?FileName=${v}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 401) {
        toastRef.current.showToast(
          "Unauthorized access. Please login again.",
          "error"
        );
        await logout();
        return;
      }

      if (!response.ok) {
        throw new Error(`Failed to fetch download URL: ${response.statusText}`);
      }

      const responseText = await response.text();
      const downloadUrl = responseText.replace(/^Url=/, "");
      if (!downloadUrl.startsWith("http")) {
        throw new Error("Invalid download URL received");
      }

      const link = document.createElement("a");
      link.href = downloadUrl;
      link.download = `${v}.xlsx`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error downloading file:", error);
      toastRef.current.showToast(
        "Error downloading file: " + error.message,
        "error"
      );
    }
  };

  useEffect(() => {
    const weekPage = document.querySelector('.week-page');

    if (weekPage) {
      let isDown = false;
      let startX;
      let scrollLeft;

      const handleMouseDown = (e) => {
        isDown = true;
        weekPage.classList.add('active');
        startX = e.pageX - weekPage.offsetLeft; 
        scrollLeft = weekPage.scrollLeft; 
      };

      const handleMouseLeave = () => {
        isDown = false;
        weekPage.classList.remove('active');
      };

      const handleMouseUp = () => {
        isDown = false;
        weekPage.classList.remove('active');
      };

      const handleMouseMove = (e) => {
        if (!isDown) return; 
        e.preventDefault(); 
        const x = e.pageX - weekPage.offsetLeft; 
        const walk = (x - startX) * 2; 
        weekPage.scrollLeft = scrollLeft - walk; 
      };
      weekPage.addEventListener('mousedown', handleMouseDown);
      weekPage.addEventListener('mouseleave', handleMouseLeave);
      weekPage.addEventListener('mouseup', handleMouseUp);
      weekPage.addEventListener('mousemove', handleMouseMove);
      return () => {
        weekPage.removeEventListener('mousedown', handleMouseDown);
        weekPage.removeEventListener('mouseleave', handleMouseLeave);
        weekPage.removeEventListener('mouseup', handleMouseUp);
        weekPage.removeEventListener('mousemove', handleMouseMove);
      };
    }
  }, []);
  

  return (
    <div className="merge-design">
      <NavSub fetchNotifications={fetchNotifications} />
      <ToastComponent ref={toastRef} timeout={10000} />
      <div className="View_force_btns">
        <div className="view_force_left_btns">
          <div
            className="Histry_view_force_calender"
            onClick={() => {
              openModal();
            }}
          >
            <ClockCounterClockwise size={24} color="#3d025f" weight="fill" />
            History
          </div>
          {userRole === "Admin" ||
              userRole === "Manager" ||
              userRole === "ProcessLead" ||
              userRole === "DepartmentLead" ? (
                <div
                className="custom-select-container"
                style={{ position: 'relative', display: 'inline-block', width: '120px',cursor:"pointer" }}
              >
                <select
                  className="import_btn_viewforce"
                  value={selectedTeam || ""}
                  onChange={handleTeamChange}
                  style={{
                    width: '100%',
                    height: '38px',
                    background: 'none',
                    border: '1px solid #50145a',
                    borderRadius: '8px',
                    fontSize: '14px',
                    paddingRight: '30px', // Space for the arrow
                    appearance: 'none',
                    cursor:"pointer",
                    WebkitAppearance: 'none', // For WebKit browsers
                  }}
                >
                  <option value="none">Deselect Team</option>
                  <option value="" disabled>
                    Team
                  </option>
                  {teamsData[0]
                    ?.filter((team) => team?.status === true && team?.type === "T")
                    ?.map((data) => (
                      <option key={data.id} value={data.id}>
                        {data.name}
                      </option>
                    ))}
                </select>
                <div
                  style={{
                    position: 'absolute',
                    right: '10px', // Adjust as needed
                    top: '50%',
                    transform: 'translateY(-50%)',
                    width: '0',
                    height: '0',
                    borderLeft: '6px solid transparent',
                    borderRight: '6px solid transparent',
                    borderTop: '6px solid #50145a', // Arrow color
                    pointerEvents: 'none', // Ensure arrow does not block interactions
                  }}
                ></div>
              </div>
              
              
               
              ) : null}
          {isModalOpen && (
            <div className="fixed top-0 left-0 z-10 flex items-center justify-center w-full h-full bg-black bg-opacity-50">
              <div className="modalLayout">
                <div className="modalLayout_contact">
                  <div className="modalLayoutMain_contact">
                    <div className="modalStyling">
                      <div className="flex justify-between">
                        <div className="text-lg font-semibold text-left font-poppins leading-36 text-custom-purple">
                          {" "}
                          History
                        </div>
                        <XCircle
                          onClick={closeModal}
                          size={32}
                          weight="fill"
                          className="text-rgba-243-237-255"
                        />
                      </div>

                      <div className="ViewForce_data_modal">
                        {historyDatamodel.map((item, index) => (
                          <div key={index} className="content-history">
                            <div className="design-all">{item.requestDate}</div>
                            Updated by
                            <div className="design-all">
                              {user?.EM_FirstName || item.requestedby || "N/A"}
                            </div>
                            <div
                              className="download_view_force"
                              onClick={() => {
                                //console.log("Item:", item); // Check item
                                if (item && item.Blobref) {
                                  downloadFile(item.Blobref);
                                } else {
                                  console.error(
                                    "Blobref is undefined or item is not available"
                                  );
                                  toastRef.current.showToast(
                                    "Error: Blobref is undefined",
                                    "error"
                                  );
                                }
                              }}
                            >
                              download
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      
        <div className="view_force_right_btns">
        <Button
              aria-controls="export-menu"
              aria-haspopup="true"
              // endIcon={<CaretDown style={{ color: "#333" }} weight="fill" />}
              style={{ color: "#333" }}
              onClick={handleDownloadSample}
            >
             Download Sample 
            </Button>
        
          <div>
            <div
              // className="history_btn"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent:"center",
                cursor: "pointer",
                background: "none",
                color: "#3d025f",
                gap:"16px"
              }}
            >
            
              {/* <select
                value={selectedYear}
                onChange={handleYearChange}
                id="yearDropdown"
                style={{ background: "none", border: "none" }}
              >
                <option value="" disabled>
                  Year
                </option>
                {years.map((year) => (
                  <option key={year} value={year}>
                    {year}
                  </option>
                ))}
              </select>
              <select
                value={selectedMonth}
                onChange={handleMonthChange}
                id="monthDropdown"
                style={{ background: "none", border: "none" }}
              >
                <option value="" disabled>
                  Month
                </option>
                {months.map((month, index) => (
                  <option key={index} value={index + 1}>
                    {month}
                  </option>
                ))}
              </select> */}
                <div className="calendar-picker" ref={calendarWrapperRef}>
      <button onClick={toggleCalendar} className="calendar-button">
        {`${months[selectedMonth - 1]} ${selectedYear}`}
      </button>
      {isCalendarOpen && (
        <div className="calendar-dropdown">
          <div className="year-selector">
            <button onClick={handlePreviousYear} className="year-nav">&lt;</button>
            <span className="year-display">{selectedYear}</span>
            <button onClick={handleNextYear} className="year-nav">&gt;</button>
          </div>

          <div className="month-grid">
            {months.map((month, index) => (
              <div
                key={index}
                className={`month-item ${index + 1 === selectedMonth ? "selected" : ""}`}
                onClick={() => handleMonthChange(index + 1)}
              >
                {month}
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
            </div>
          </div>
          {loading ? (
            <div>
              <CircularProgress width={24} style={{ color: "purple" }} />
            </div>
          ) : (
            <div className="import_btn_viewforce">
              <Download size={24} color="#3d025f" weight="fill" />
              <label className="Nav-Sub-btn">
                IMPORT FILE
                <input
                  type="file"
                  style={{ display: "none" }}
                  multiple={false}
                  onChange={handleFileUpload}
                />
              </label>
            </div>
          )}
          <div className="Export_btn_viewforce">
            <Button
              aria-controls="export-menu"
              aria-haspopup="true"
              endIcon={<CaretDown style={{ color: "#fff" }} weight="fill" />}
              style={{ color: "#fff" }}
              onClick={handleExportClick}
            >
              Export Files
            </Button>
            <Menu
              id="export-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleExportClose}
            >
              <MenuItem onClick={handleExportFiles}>Export as Excel</MenuItem>
            </Menu>
          </div>
         
        </div>
      </div>
      {loading ? (
         <div style={{display:"flex",justifyContent:"center",alignItems:"center"}}>
         <CircularProgress width={24} style={{ color: "purple" }} />
       </div>
      ):(
      <div className="week-page">
        <div className="fixed-container"   >
          <div className="main-newcontent11">
            <div className="Content-week2211">
              <div className="slider-container22111" >
                <div className="Content-w21" >
                  <div
                    className="Content-weeklabel123"
                    style={{ minWidth: "400px" }}
                  >
                    PROJECT NAME
                  </div>
                </div>
                <div className="Content-w21">
                  <div
                    className="Content-weeklabel123"
                    style={{ minWidth: "360px" }}
                  >
                    ACCOUNT NAME
                  </div>
                </div>
                <div className="Content-w21">
                  <div
                    className="Content-weeklabel1213"
                    style={{ minWidth: "200px" }}
                  >
                    ORG NUMBER
                  </div>
                </div>
              </div>
              {!selectedYear &&
                !selectedMonth &&
                !selectedTeam &&
                latestData?.map((item, index) => (
                  <div
                    className={
                      index % 2 === 0 ? "Content2-week2" : "Content2-week22"
                    }
                    key={index}
                  >
                    <div className="slider2-container">
                      <div
                        className="Content2-w21"
                        style={{ minWidth: "400px" }}
                      >
                        <div className="Content2-weeklabel123">
                          {item.projectTaskName}
                        </div>
                      </div>
                      <div
                        className="Content2-w21"
                        style={{ minWidth: "400px", textAlign: "left" ,paddingLeft:"28px" }}
                      >
                        <div className="Content2-weeklabel123">
                          {item.accountName}
                        </div>
                      </div>
                      <div
                        className="Content2-w21"
                        style={{ position: "relative", minWidth: "200px" }}
                      >
                        <div className="Content2-weeklabel111">
                          {item.org_Number}
                        </div>
                        <div
                          style={{
                            content: '""',
                            position: "absolute",
                            minHeight: "100%",
                            height: "62px",
                            width: "5px",
                            backgroundColor: "#E6E6E6",
                            right: "-15px",
                            top: "-5px",
                          }}
                        ></div>
                      </div>
                    </div>
                  </div>
                ))}
              {data?.map((item, index) => (
                <div
                  className={
                    index % 2 === 0 ? "Content2-week2" : "Content2-week22"
                  }
                  key={index}
                >
                  <div className="slider2-container">
                    <div className="Content2-w21" style={{ minWidth: "400px" }}>
                      <div className="Content2-weeklabel123">
                        {item.projectTaskName}
                      </div>
                    </div>
                    <div
                      className="Content2-w21"
                      style={{ minWidth: "400px", textAlign: "left" ,paddingLeft:"28px" }}
                    >
                      <div className="Content2-weeklabel123">
                        {item.accountName}
                      </div>
                    </div>
                    <div
                      className="Content2-w21"
                      style={{ position: "relative", minWidth: "200px"}}
                    >
                      <div className="Content2-weeklabel111">
                        {item.org_Number}
                      </div>
                      <div
                        style={{
                          content: '""',
                          position: "absolute",
                          minHeight: "100%",
                          height: "62px",
                          width: "5px",
                          backgroundColor: "#E6E6E6",
                          right: "-15px",
                          top: "-5px",
                        }}
                      ></div>{" "}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className="scrollable-container" >
        {/* <Slider {...settings}> */}
          <div className="main-newcontent11">
            <div className="Content-week2211">
              
              <div className="slider-container2211">
                <div className="Content-w21">
                  <div
                    className="Content-weeklabel112"
                    style={{ minWidth: "330px" }}
                  >
                    COST CENTRE
                  </div>
                </div>
                <div className="Content-w21">
                  <div
                    className="Content-weeklabel11"
                    style={{ minWidth: "330px" }}
                  >
                    ACTIVITY
                  </div>
                </div>
                <div className="Content-w21">
                  <div
                    className="Content-weeklabel11"
                    style={{ minWidth: "330px" }}
                  >
                    FULL NAME
                  </div>
                </div>
                <div className="Content-w21">
                  <div
                    className="Content-weeklabel11"
                    style={{ minWidth: "330px" }}
                  >
                    TIME ID
                  </div>
                </div>
                <div className="Content-w21">
                  <div
                    className="Content-weeklabel11"
                    style={{ minWidth: "330px" }}
                  >
                    WEEK NO
                  </div>
                </div>
                <div className="Content-w21">
                  <div
                    className="Content-weeklabel11"
                    style={{ minWidth: "330px" }}
                  >
                    DATE INCURRED
                  </div>
                </div>
                <div className="Content-w21">
                  <div
                    className="Content-weeklabel11"
                    style={{ minWidth: "330px" }}
                  >
                    REGISTERED TIME
                  </div>
                </div>
                <div className="Content-w21">
                  <div
                    className="Content-weeklabel11"
                    style={{ minWidth: "330px" }}
                  >
                    BILLABLE TIME
                  </div>
                </div>
                <div className="Content-w21">
                  <div
                    className="Content-weeklabel11"
                    style={{ minWidth: "330px" }}
                  >
                    MAIN PRICE AGREEMENT
                  </div>
                </div>
                <div className="Content-w21">
                  <div
                    className="Content-weeklabel11"
                    style={{ minWidth: "330px" }}
                  >
                    ACTIVITY PRICE AGREEMENT
                  </div>
                </div>
                <div className="Content-w21">
                  <div
                    className="Content-weeklabel11"
                    style={{ minWidth: "330px" }}
                  >
                    MAIN ACTIVITY
                  </div>
                </div>
                <div className="Content-w21">
                  <div
                    className="Content-weeklabel11"
                    style={{ minWidth: "330px" }}
                  >
                    DESCRIPTION
                  </div>
                </div>
                <div className="Content-w21">
                  <div
                    className="Content-weeklabel11"
                    style={{ minWidth: "330px" }}
                  >
                    STATUS
                  </div>
                </div>
                <div className="Content-w21">
                  <div
                    className="Content-weeklabel11"
                    style={{ minWidth: "200px" }}
                  >
                    CONTROL CHECK
                  </div>
                </div>
                <div className="Content-w21">
                  <div
                    className="Content-weeklabel11"
                    style={{ minWidth: "200px" }}
                  >
                    STR CHECK 1
                  </div>
                </div>
                <div className="Content-w21">
                  <div
                    className="Content-weeklabel11"
                    style={{ minWidth: "200px" }}
                  >
                    10 HRS CHECK 2
                  </div>
                </div>
                <div className="Content-w21">
                  <div
                    className="Content-weeklabel11"
                    style={{ minWidth: "200px" }}
                  >
                    HOURLY RATE CHECK 3
                  </div>
                </div>
                <div className="Content-w21">
                  <div
                    className="Content-weeklabel11"
                    style={{ minWidth: "200px" }}
                  >
                    RT-BT CHECK 4
                  </div>
                </div>
                <div className="Content-w21">
                  <div
                    className="Content-weeklabel11"
                    style={{ minWidth: "200px" }}
                  >
                    MAIN ACTIVITY CHECK 5
                  </div>
                </div>
                <div className="Content-w21">
                  <div
                    className="Content-weeklabel11"
                    style={{ minWidth: "200px" }}
                  >
                    COMMENTS CHECK 6
                  </div>
                </div>
                <div className="Content-w21">
                  <div
                    className="Content-weeklabel11"
                    style={{ minWidth: "200px" }}
                  >
                    APPROVAL CHECK 7
                  </div>
                </div>
                <div className="Content-w21">
                  <div
                    className="Content-weeklabel11"
                    style={{ minWidth: "200px" }}
                  >
                    ADHOC CHECK 8
                  </div>
                </div>
              </div>
              {!selectedYear &&
                !selectedMonth &&
                !selectedTeam &&
                latestData?.map((item, index) => (
                  <div
                    className={
                      index % 2 === 0 ? "Content2-week2" : "Content2-week22"
                    }
                    key={index}
                  >
                    <div className="slider2-container">
                      <div
                        className="Content2-w21"
                        style={{ minWidth: "330px" }}
                      >
                        <div className="Content2-weeklabel11">
                          {item.costCenter}
                        </div>
                      </div>
                      <div
                        className="Content2-w21"
                        style={{ minWidth: "330px" }}
                      >
                        <div className="Content2-weeklabel11">
                          {item.activity}
                        </div>
                      </div>
                      <div
                        className="Content2-w21"
                        style={{ minWidth: "330px" }}
                      >
                        <div className="Content2-weeklabel11">
                          {item.fullName}
                        </div>
                      </div>
                      <div
                        className="Content2-w21"
                        style={{ minWidth: "330px" }}
                      >
                        <div className="Content2-weeklabel11">
                          {item.timeId}
                        </div>
                      </div>
                      <div
                        className="Content2-w21"
                        style={{ minWidth: "330px" }}
                      >
                        <div className="Content2-weeklabel11">
                          {item.weekNumber}
                        </div>
                      </div>
                      <div
                        className="Content2-w21"
                        style={{ minWidth: "330px" }}
                      >
                        <div className="Content2-weeklabel11">
                          {item.dateIncurred}
                        </div>
                      </div>
                      <div
                        className="Content2-w21"
                        style={{ minWidth: "330px" }}
                      >
                        <div className="Content2-weeklabel11">
                          {item.registeredTime}
                        </div>
                      </div>
                      <div
                        className="Content2-w21"
                        style={{ minWidth: "330px" }}
                      >
                        <div className="Content2-weeklabel11">
                          {item.billableTime}
                        </div>
                      </div>
                      <div
                        className="Content2-w21"
                        style={{ minWidth: "330px" }}
                      >
                        <div className="Content2-weeklabel11">
                          {item.mainPriceAgreement}
                        </div>
                      </div>
                      <div
                        className="Content2-w21"
                        style={{ minWidth: "330px" }}
                      >
                        <div className="Content2-weeklabel11">
                          {item.activityPriceAgreement}
                        </div>
                      </div>
                      <div
                        className="Content2-w21"
                        style={{ minWidth: "330px" }}
                      >
                        <div className="Content2-weeklabel11">
                          {item.mainActivity}
                        </div>
                      </div>
                      <div
                        className="Content2-w21"
                        style={{ minWidth: "330px" }}
                      >
                        <div className="Content2-weeklabel11">
                          {item.description}
                        </div>
                      </div>
                      <div
                        className="Content2-w21"
                        style={{ minWidth: "330px" }}
                      >
                        <div className="Content2-weeklabel11">
                          {item.status}
                        </div>
                      </div>
                      <div
                        className="Content2-w21"
                        style={{ minWidth: "200px" }}
                      >
                        <div
                          className="Content2-weeklabel11"
                          style={{
                            color:
                              calculateControlCheck(item) === "Ok"
                                ? "green"
                                : "orange",
                          }}
                        >
                          {calculateControlCheck(item)}
                        </div>
                      </div>
                      <div
                        className="Content2-w21"
                        style={{ minWidth: "200px" }}
                      >
                        <div
                          className="Content2-weeklabel11"
                          style={{
                            color:
                              checkSTR(
                                item.activity,
                                item.mainPriceAgreement
                              ) === "Ok"
                                ? "green"
                                : "orange",
                          }}
                        >
                          {checkSTR(item.activity, item.mainPriceAgreement)}
                        </div>
                      </div>
                      <div
                        className="Content2-w21"
                        style={{ minWidth: "200px" }}
                      >
                        <div
                          className="Content2-weeklabel11"
                          style={{
                            color:
                              check10Hrs(
                                item.registeredTime,
                                item.billableTime
                              ) === "Ok"
                                ? "green"
                                : "orange",
                          }}
                        >
                          {check10Hrs(item.registeredTime, item.billableTime)}
                        </div>
                      </div>
                      <div
                        className="Content2-w21"
                        style={{ minWidth: "200px" }}
                      >
                        <div
                          className="Content2-weeklabel11"
                          style={{
                            color:
                              checkHourlyRate(
                                item.activityPriceAgreement,
                                item.activity,
                                item.registeredTime,
                                item.billableTime
                              ) === "Ok"
                                ? "green"
                                : "orange",
                          }}
                        >
                          {checkHourlyRate(
                            item.activityPriceAgreement,
                            item.activity,
                            item.registeredTime,
                            item.billableTime
                          )}
                        </div>
                      </div>
                      <div
                        className="Content2-w21"
                        style={{ minWidth: "200px" }}
                      >
                        <div
                          className="Content2-weeklabel11"
                          style={{
                            color:
                              checkRTBT(
                                item.registeredTime,
                                item.billableTime
                              ) === "Ok"
                                ? "green"
                                : "orange",
                          }}
                        >
                          {checkRTBT(item.registeredTime, item.billableTime)}
                        </div>
                      </div>
                      <div
                        className="Content2-w21"
                        style={{ minWidth: "200px" }}
                      >
                        <div
                          className="Content2-weeklabel11"
                          style={{
                            color:
                              checkMainActivity(item.mainActivity) === "Ok"
                                ? "green"
                                : "orange",
                          }}
                        >
                          {checkMainActivity(item.mainActivity)}
                        </div>
                      </div>
                      <div
                        className="Content2-w21"
                        style={{ minWidth: "200px" }}
                      >
                        <div
                          className="Content2-weeklabel11"
                          style={{
                            color:
                              checkComments(item.activity, item.description) ===
                              "Ok"
                                ? "green"
                                : "orange",
                          }}
                        >
                          {checkComments(item.activity, item.description)}
                        </div>
                      </div>
                      <div
                        className="Content2-w21"
                        style={{ minWidth: "200px" }}
                      >
                        <div
                          className="Content2-weeklabel11"
                          style={{
                            color:
                              checkApproval(item.dateIncurred, item.status) ===
                              "Ok"
                                ? "green"
                                : "orange",
                          }}
                        >
                          {checkApproval(item.dateIncurred, item.status)}
                        </div>
                      </div>
                      <div
                        className="Content2-w21"
                        style={{ minWidth: "200px" }}
                      >
                        <div
                          className="Content2-weeklabel11"
                          style={{
                            color:
                              checkAdhoc(
                                item.projectTaskName,
                                item.accountName
                              ) === "Ok"
                                ? "green"
                                : "orange",
                          }}
                        >
                          {checkAdhoc(item.projectTaskName, item.accountName)}
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              {data?.map((item, index) => (
                <div
                  className={
                    index % 2 === 0 ? "Content2-week2" : "Content2-week22"
                  }
                  key={index}
                >
                  <div className="slider2-container">
                    <div className="Content2-w21" style={{ minWidth: "330px" }}>
                      <div className="Content2-weeklabel11">
                        {item.costCenter}
                      </div>
                    </div>
                    <div className="Content2-w21" style={{ minWidth: "330px" }}>
                      <div className="Content2-weeklabel11">
                        {item.activity}
                      </div>
                    </div>
                    <div className="Content2-w21" style={{ minWidth: "330px" }}>
                      <div className="Content2-weeklabel11">
                        {item.fullName}
                      </div>
                    </div>
                    <div className="Content2-w21" style={{ minWidth: "330px" }}>
                      <div className="Content2-weeklabel11">{item.timeId}</div>
                    </div>
                    <div className="Content2-w21" style={{ minWidth: "330px" }}>
                      <div className="Content2-weeklabel11">
                        {item.weekNumber}
                      </div>
                    </div>
                    <div className="Content2-w21" style={{ minWidth: "330px" }}>
                      <div className="Content2-weeklabel11">
                        {item.dateIncurred}
                      </div>
                    </div>
                    <div className="Content2-w21" style={{ minWidth: "330px" }}>
                      <div className="Content2-weeklabel11">
                        {item.registeredTime}
                      </div>
                    </div>
                    <div className="Content2-w21" style={{ minWidth: "330px" }}>
                      <div className="Content2-weeklabel11">
                        {item.billableTime}
                      </div>
                    </div>
                    <div className="Content2-w21" style={{ minWidth: "330px" }}>
                      <div className="Content2-weeklabel11">
                        {item.mainPriceAgreement}
                      </div>
                    </div>
                    <div className="Content2-w21" style={{ minWidth: "330px" }}>
                      <div className="Content2-weeklabel11">
                        {item.activityPriceAgreement}
                      </div>
                    </div>
                    <div className="Content2-w21" style={{ minWidth: "330px" }}>
                      <div className="Content2-weeklabel11">
                        {item.mainActivity}
                      </div>
                    </div>
                    <div className="Content2-w21" style={{ minWidth: "330px" }}>
                      <div className="Content2-weeklabel11">
                        {item.description}
                      </div>
                    </div>
                    <div className="Content2-w21" style={{ minWidth: "330px" }}>
                      <div className="Content2-weeklabel11">{item.status}</div>
                    </div>
                    <div className="Content2-w21" style={{ minWidth: "200px" }}>
                      <div
                        className="Content2-weeklabel11"
                        style={{
                          color:
                            calculateControlCheck(item) === "Ok"
                              ? "green"
                              : "orange",
                        }}
                      >
                        {calculateControlCheck(item)}
                      </div>
                    </div>
                    <div className="Content2-w21" style={{ minWidth: "200px" }}>
                      <div
                        className="Content2-weeklabel11"
                        style={{
                          color:
                            checkSTR(item.activity, item.mainPriceAgreement) ===
                            "Ok"
                              ? "green"
                              : "orange",
                        }}
                      >
                        {checkSTR(item.activity, item.mainPriceAgreement)}
                      </div>
                    </div>
                    <div className="Content2-w21" style={{ minWidth: "200px" }}>
                      <div
                        className="Content2-weeklabel11"
                        style={{
                          color:
                            check10Hrs(
                              item.registeredTime,
                              item.billableTime
                            ) === "Ok"
                              ? "green"
                              : "orange",
                        }}
                      >
                        {check10Hrs(item.registeredTime, item.billableTime)}
                      </div>
                    </div>
                    <div className="Content2-w21" style={{ minWidth: "200px" }}>
                      <div
                        className="Content2-weeklabel11"
                        style={{
                          color:
                            checkHourlyRate(
                              item.activityPriceAgreement,
                              item.activity,
                              item.registeredTime,
                              item.billableTime
                            ) === "Ok"
                              ? "green"
                              : "orange",
                        }}
                      >
                        {checkHourlyRate(
                          item.activityPriceAgreement,
                          item.activity,
                          item.registeredTime,
                          item.billableTime
                        )}
                      </div>
                    </div>
                    <div className="Content2-w21" style={{ minWidth: "200px" }}>
                      <div
                        className="Content2-weeklabel11"
                        style={{
                          color:
                            checkRTBT(
                              item.registeredTime,
                              item.billableTime
                            ) === "Ok"
                              ? "green"
                              : "orange",
                        }}
                      >
                        {checkRTBT(item.registeredTime, item.billableTime)}
                      </div>
                    </div>
                    <div className="Content2-w21" style={{ minWidth: "200px" }}>
                      <div
                        className="Content2-weeklabel11"
                        style={{
                          color:
                            checkMainActivity(item.mainActivity) === "Ok"
                              ? "green"
                              : "orange",
                        }}
                      >
                        {checkMainActivity(item.mainActivity)}
                      </div>
                    </div>
                    <div className="Content2-w21" style={{ minWidth: "200px" }}>
                      <div
                        className="Content2-weeklabel11"
                        style={{
                          color:
                            checkComments(item.activity, item.description) ===
                            "Ok"
                              ? "green"
                              : "orange",
                        }}
                      >
                        {checkComments(item.activity, item.description)}
                      </div>
                    </div>
                    <div className="Content2-w21" style={{ minWidth: "200px" }}>
                      <div
                        className="Content2-weeklabel11"
                        style={{
                          color:
                            checkApproval(item.dateIncurred, item.status) ===
                            "Ok"
                              ? "green"
                              : "orange",
                        }}
                      >
                        {checkApproval(item.dateIncurred, item.status)}
                      </div>
                    </div>
                    <div className="Content2-w21" style={{ minWidth: "200px" }}>
                      <div
                        className="Content2-weeklabel11"
                        style={{
                          color:
                            checkAdhoc(
                              item.projectTaskName,
                              item.accountName
                            ) === "Ok"
                              ? "green"
                              : "orange",
                        }}
                      >
                        {checkAdhoc(item.projectTaskName, item.accountName)}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          {/* </Slider> */}
        </div>
      </div>
      )}

{isModalOpenError && (
  <div className="fixed top-0 left-0 z-10 flex items-center justify-center w-full h-full bg-black bg-opacity-50">
    <div className="modalLayout">
      <div className="modalLayout_contact">
        <div className="modalLayoutMain_contact">
          <div className="modalStyling">
            <div className="flex justify-between">
              <div className="text-lg font-semibold text-left font-poppins leading-36 text-custom-purple">
                Error
              </div>
              <XCircle
                onClick={handleCloseErrorModal}
                size={32}
                weight="fill"
                className="text-rgba-243-237-255 cursor-pointer"
              />
            </div>
            <div style={{ margin: "40px auto"}}>
              <p className="font-semibold"  style={{ marginBottom: "10px" }}>
              File processing failed. Please review the following errors:
              </p>
              <ul className="list-inside">
                {errorMessage.split('.').filter(Boolean).map((error, index) => (
                  <li key={index} >
                    {index + 1}. {error.trim()}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
)}



    </div>
  );
}
export default ViewForceSupUser;
