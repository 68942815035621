import React, { useState, useContext, useEffect } from "react";
import "../Styles/Dashboard_graph4.css";
import "../Styles/Dashboard.css";
import { MenuItem } from "@mui/material";
import { Select } from "@mui/material";
import { AuthContext } from "../context/AuthContext";
import { UserContext } from "../context/UserContext";
import { useNavigate } from "react-router-dom";
import {
  clientApiUrl,
  taskApiUrl,
  notificationApiUrl,
  userApiUrl,
} from "../utils/GetUrl";
function Dashboard_graph_4() {
  const navigate = useNavigate();
  const { token, userData } = useContext(AuthContext);
  const { teamsData, usersList } = useContext(UserContext);
  const [selectedTeam, setSelectedTeam] = useState(userData?.[0]?.DR_Name==="Admin"?'':userData?.[0]?.STM_Team);
  const [filteredEmployeeList, setFilteredEmployeeList] = useState([]);
  const [selectedMonth, setSelectedMonth] = useState(new Date().toISOString());

  const [controlData, setControlData] = useState([]);
  const [filteredControlData, setFilteredControlData] = useState({
    completed: 0,
    pending: 0,
    notUpdated: 0,
    overdue:0
  });

  // const fetchControlData = async (monthString) => {
  //   const [year, month] = monthString.split("-");
  //   const lastDay = new Date(year, month, 0).getDate();
  //   const firstDay = "01";

  //   const formattedDateRange1 = `${year}-${month}-${firstDay}`;
  //   const formattedDateRange2 = `${year}-${month}-${lastDay
  //     .toString()
  //     .padStart(2, "0")}`;

  //   try {
  //     const response = await fetch(
  //       `https://fn-hampi-taskmanager.azurewebsites.net/api/GetControlPointMapping?DateRange1=${formattedDateRange1}&DateRange2=${formattedDateRange2}&Emids=${filteredEmployeeList}`,
  //       {
  //         headers: {
  //           Authorization: `Bearer ${token}`,
  //         },
  //       }
  //     );
  //     if (response?.status === 401) {
  //       navigate("/");
  //     }
  //     if (!response.ok) {
  //       throw new Error("Network response was not ok");
  //     }
  //     const data = await response.json();
  //     console.log("Fetched control data:", data);
  //     setControlData(data);
  //     const completed = data?.filter(
  //       (item) => item.Status === "Completed"
  //     ).length;
  //     const pending = data?.filter((item) => item.Status === "pending").length;
  //     const notUpdated = data?.filter(
  //       (item) => item.Status === "Not Updated"
  //     ).length;

  //     setFilteredControlData({ completed, pending, notUpdated });
  //   } catch (error) {
  //     console.error("Error fetching control data:", error);
  //   }
  // };

  const fetchControlData = async (monthString) => {
    const [year, month] = monthString?.split("-"); // Split the monthString into year and month
  
    const lastDay = new Date(year, month, 0).getDate(); // Last day of the month
    const firstDay = "01"; // First day of the month
  
    const formattedDateRange1 = `${year}-${month}-${firstDay}`;
    const formattedDateRange2 = `${year}-${month}-${lastDay.toString().padStart(2, "0")}`; // Ensure two digits for day
  
    // Get previous month's year and month
    const prevMonth = month === "01" ? 12 : Number(month) - 1;
    const prevYear = month === "01" ? Number(year) - 1 : year;
    const prevLastDay = new Date(prevYear, prevMonth, 0).getDate();
  
    const prevFormattedDateRange1 = `${prevYear}-${String(prevMonth).padStart(2, "0")}-01`;
    const prevFormattedDateRange2 = `${prevYear}-${String(prevMonth).padStart(2, "0")}-${prevLastDay.toString().padStart(2, "0")}`;
  
    // Helper function to chunk an array into smaller arrays of a given size
    const chunkArray = (arr, chunkSize) => {
      const chunks = [];
      for (let i = 0; i < arr.length; i += chunkSize) {
        chunks.push(arr.slice(i, i + chunkSize));
      }
      return chunks;
    };
  
    // Ensure filteredEmployeeList is an array of strings by splitting it on commas
    console.log(filteredEmployeeList)
    const filteredEmployeeListArray = typeof filteredEmployeeList === 'string' 
    ? filteredEmployeeList.split(',') 
    : '';    if(selectedTeam&&filteredEmployeeListArray[0]==''){
      setFilteredControlData({
        completed: 0,
        pending: 0,
        notUpdated: 0,
        overdue:0
      })
      return;
    }


    // Split the filteredEmployeeList into chunks of 20
    const employeeChunks = chunkArray(filteredEmployeeListArray, 20);
  
    const fetchDataForEmployeeChunk = async (chunk) => {
      // Ensure chunk is an array of strings and join them with commas for the API request
      const employeeIds = chunk.join(',');
  
      // Fetch current month's data for the chunk
      const currentMonthResponse = await fetch(
        `${taskApiUrl}/GetControlPointMapping?DateRange1=${formattedDateRange1}&DateRange2=${formattedDateRange2}&Emids=${employeeIds}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (currentMonthResponse?.status === 401) {
        navigate("/");
      }
      if (!currentMonthResponse.ok) {
        throw new Error("Network response was not ok");
      }
      const currentMonthData = await currentMonthResponse.json();
  
      // Fetch previous month's data for the chunk
      const prevMonthResponse = await fetch(
        `${taskApiUrl}/GetControlPointMapping?DateRange1=${prevFormattedDateRange1}&DateRange2=${prevFormattedDateRange2}&Emids=${employeeIds}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (prevMonthResponse?.status === 401) {
        navigate("/");
      }
      if (!prevMonthResponse.ok) {
        throw new Error("Network response was not ok");
      }
      const prevMonthData = await prevMonthResponse.json();
  
      return { currentMonthData, prevMonthData };
    };
  
    try {
      // Fetch data for each chunk and combine the results
      const allData = await Promise.all(employeeChunks?.map(fetchDataForEmployeeChunk));
  
      let combinedCurrentMonthData = [];
      let combinedPrevMonthData = [];
  
      // Combine all the fetched data
      allData.forEach(({ currentMonthData, prevMonthData }) => {
        combinedCurrentMonthData = combinedCurrentMonthData?.concat(currentMonthData);
        combinedPrevMonthData = combinedPrevMonthData?.concat(prevMonthData);
      });
  
      // Helper function to check if the control point is overdue based on frequency
      const isOverdue = (item, isPrevMonth = false) => {
        const today = new Date();
        const controlDate = new Date(item.CreatedDate);
  
        // Helper function to determine the end of a quarter
        const isQuarterOverdue = (controlDate) => {
          const currentMonth = today.getMonth();
          const quarterEndMonths = [2, 5, 8, 11]; // March, June, September, December
  
          // Check if the control point was created in or before the last quarter
          const controlQuarter = quarterEndMonths.find((month) => controlDate.getMonth() <= month);
          const nextQuarterEnd = new Date(today.getFullYear(), controlQuarter, 31); // End of current quarter
  
          return today > nextQuarterEnd; // Overdue if today is past the quarter's end
        };
  
        switch (item.Frequency) {
          case 'Daily':
            return controlDate < today; // Daily: Check if control point is before today
          case 'Weekly':
            const nextWeek = new Date(controlDate);
            nextWeek.setDate(controlDate.getDate() + 7); // Weekly: Add 7 days
            return nextWeek < today;
          case 'Bi-Monthly':
            const currentMonth = today.getMonth();
            const firstCheckDate = new Date(controlDate);
            firstCheckDate.setDate(14); // Assume Bi-Monthly check happens on 14th and 28th
            const secondCheckDate = new Date(controlDate);
            secondCheckDate.setDate(28);
            return today > firstCheckDate && today > secondCheckDate && currentMonth === controlDate.getMonth();
          case 'Monthly':
            const nextMonth = new Date(controlDate);
            nextMonth.setMonth(controlDate.getMonth() + 1); // Monthly: Add 1 month
            return nextMonth < today;
          case 'Quarterly':
            if (isPrevMonth) {
              return isQuarterOverdue(controlDate); // Check if the previous quarter is overdue
            } else {
              const nextQuarter = new Date(controlDate);
              nextQuarter.setMonth(controlDate.getMonth() + 3); // Quarterly: Add 3 months
              return nextQuarter < today;
            }
          default:
            return isPrevMonth;
        }
      };
  
      // Pending control points (P status)
      const pending = combinedCurrentMonthData?.filter(
        (item) => item.Status === "P" && !isOverdue(item)
      ).length;
  
      // Not updated control points (P status in previous month)
      const notUpdated = combinedPrevMonthData?.filter(
        (item) => item.Status === "NA"
      ).length;
  
      // Completed control points (C status)
      const completed = combinedCurrentMonthData?.filter(
        (item) => item.Status === "C"
      ).length;
  
      // Overdue control points (P status and created date is passed)
      const overdue1 = combinedCurrentMonthData?.filter(
        (item) => item.Status === "P" && isOverdue(item)
      ).length;
      const overdue2 = combinedPrevMonthData?.filter(
        (item) => item.Status === "P" && isOverdue(item)
      ).length;
  
      const overdue = overdue1 + overdue2;
  
      // Log or use the variables as needed
      console.log({ pending, notUpdated, completed, overdue });
  
      // Update the state with the computed values
      setFilteredControlData({ completed, pending, notUpdated, overdue });
    } catch (error) {
      console.error("Error fetching control data:", error);
    }
  };
  
  

 
  
  

  useEffect(() => {
    fetchControlData(selectedMonth);
  }, [token, filteredEmployeeList, selectedMonth]);

  function handleMonthChange(monthString) {
    //console.log(monthString);
    setSelectedMonth(monthString);
  }
  const currentDate = new Date();
  const currentMonthYear = `${currentDate.getFullYear()}-${String(
    currentDate.getMonth() + 1
  ).padStart(2, "0")}`;

  const handleTeamChange = (e) => {
    const value = e.target.value;
    setSelectedTeam(value);
    //console.log(value);
  };

  useEffect(() => {
    // alert(selectedTeam)
    let filteredEmployees;
    if(!selectedTeam){
      filteredEmployees =(usersList);
    }
    else{
      filteredEmployees = usersList?.filter(
        (user) => user?.STM_Team == selectedTeam
      );
    }
     
    //console.log(filteredEmployees);
    setFilteredEmployeeList(
      filteredEmployees?.map((item) => `'${item["EM_ID"]}'`).join(",")
    );
  }, [selectedTeam]);

  const handleClick = () => {
    navigate("/QualityControl"); 
  };

  return (
    <div className="g4-main">
      <div className="g4-main-drop-div">
        <div className="g4-head" onClick={handleClick} style={{ cursor: "pointer" }}>Control points</div>
        <div className="dropdown-g4-side-right">
          <div>
            <input
              type="month"
              value={selectedMonth.slice(0, 7)}
              onChange={(e) => handleMonthChange(e.target.value)}
              className="calstyle-for-dashboard"
            />
          </div>
          {userData?.[0]?.DR_Name!="Consultant"&&<Select
            fullWidth
            value={selectedTeam}
            onChange={handleTeamChange}
            displayEmpty
          >
            <MenuItem value="">Teams</MenuItem>
            {teamsData[0]
              ?.filter((team) => team?.status === true && team?.type === "T")
              ?.map((data) => (
                <MenuItem key={data.id} value={data.id}>
                  {data.name}
                </MenuItem>
              ))}
          </Select>}
        </div>
      </div>
      <div className="g4-subhead">Live status of control points</div>
      <div className="g4-cards-div">
        <div className="g4-card1-dash">
          <div className="g4-card1-dash-c1">Completed :</div>
          <div className="g4-card1-dash-c2">
            {filteredControlData.completed}
          </div>
        </div>
        <div className="g4-card2-dash">
          <div className="g4-card1-dash-c1">Pending :</div>
          <div className="g4-card1-dash-c3">{filteredControlData.pending}</div>
        </div>
        <div className="g4-card3-dash">
          <div className="g4-card1-dash-c1">OverDue :</div>
          <div className="g4-card1-dash-c4">
            {filteredControlData.overdue}
          </div>
        </div>
      </div>
      <div className="g4-dash-footer">
        <div className="g4-dash-footer-data">
          Total count and time utilized Count :
          <span className="g4-dash-footer-spam">
            {filteredControlData.completed +
              filteredControlData.pending +
              filteredControlData.notUpdated}{" "}
            {/* | Time : 111 */}
          </span>
        </div>
      </div>
    </div>
  );
}

export default Dashboard_graph_4;
