import React, { useState, useContext, useEffect, useRef } from "react";
import Slider from "react-slick";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { AuthContext } from "../context/AuthContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretRight, faCaretLeft } from "@fortawesome/free-solid-svg-icons";
import "@fortawesome/fontawesome-svg-core/styles.css";
import { MenuItem } from "@mui/material";
import { Select } from "@mui/material";
import "../Styles/Multicircular-Progress.css";
import { UserContext } from "../context/UserContext";
import {
  clientApiUrl,
  taskApiUrl,
  notificationApiUrl,
  userApiUrl,
} from "../utils/GetUrl";
import { useNavigate } from "react-router-dom";
function MultipleCircularProgress() {
  const { token , userData} = useContext(AuthContext);
  const sliderRef = useRef(null);
  const currentDate = new Date();
  const { teamsData, usersList, teamNames } = useContext(UserContext);

  const [selectedTeam, setSelectedTeam] = useState(userData?.[0]?.DR_Name === "Admin" ? '' : teamsData[0]?.filter(t=>t.id==userData?.[0]?.STM_Team)?.[0]?.name);  
  const [selectedYear, setSelectedYear] = useState("");
  const [capacityBuget, setcapacityBuget] = useState([]);
  const [budgetData, setBudgetData] = useState([]);

  const [controlData, setControlData] = useState([]);
  const years = Array.from({ length: 10 }, (_, index) => 2020 + index);
  const [filteredTeam, setFilterTeam] = useState(usersList);
  const [filteredEmployeeList, setFilteredEmployeeList] = useState([]);
  const [averageMonthlyValues, setAverageMonthlyValues] = useState([]);
  const [averageYTD, setAverageYTD] = useState(0);
  const currentYear = new Date().getFullYear();
  const [mtd, setMTD] = useState("");

  const [selectedId, setselectedId] = useState(userData?.[0]?.DR_Name=="Admin"?'':userData?.[0]?.STM_Team);
  if (!years.includes(currentYear)) {
    years.push(currentYear);
    years.sort((a, b) => a - b);
  }
  useEffect(() => {
    setSelectedYear(currentYear);
  }, [currentYear]);

  const handleTeamChange = (e) => {
    const value = e.target.value;
    setSelectedTeam(value);

    const selectedTeam = teamsData[0]?.find((team) => team.name === value);

    setselectedId(selectedTeam?.id || "");
  };

  useEffect(() => {
    const months = [
      "jan",
      "feb",
      "mar",
      "apr",
      "may",
      "jun",
      "jul",
      "aug",
      "sep",
      "oct",
      "nov",
      "decs",
    ];

    const calculateAverages = (data) => {
      return months.map((month) => {
        const total = data.reduce((sum, item) => sum + item[month], 0);
        return Math.round(total / data.length);
      });
    };
    const filteredDataWithoutTeam = capacityBuget.filter((item) => item.team);
    if (selectedTeam) {
      const filteredData = filteredDataWithoutTeam.filter(
        (item) => item.team === selectedTeam
      );
      if (filteredData.length > 0) {
        setAverageMonthlyValues(calculateAverages(filteredData));
      } else {
        setAverageMonthlyValues([]);
      }
    } else {
      setAverageMonthlyValues(calculateAverages(filteredDataWithoutTeam));
    }
  }, [selectedTeam, capacityBuget]);

  const handleChange = (event) => {
    setSelectedYear(event.target.value);
  };
  const getMTDForUser = (user) => {
    return getCapacityByUserAndMonth(user, previousMonth); // Ensure previousMonth is correctly set
  };

  const getBudgetedMtdForUser = (user) => {
    const monthNames = [
      "jan",
      "feb",
      "mar",
      "apr",
      "may",
      "jun",
      "jul",
      "aug",
      "sep",
      "oct",
      "nov",
      "decs",
    ];
    const previousMonthIndex = new Date().getMonth() - 1; // Adjust index
    const previousMonthName = monthNames[previousMonthIndex];
    const budgetDataForUser = budgetData.find(
      (data) => data.empid === user.EM_EmpID
    );
    return budgetDataForUser
      ? parseInt(budgetDataForUser[previousMonthName]) || 0
      : 0;
  };

  const fetchForecast = async () => {
    if (!selectedYear) return;
    try {
      const response = await fetch(
        `${userApiUrl}/GetLatestBudget?Year=${selectedYear}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response?.status === 401) {
        navigate("/");
      }

      if (!response.ok) {
        throw new Error("Failed to fetch budget history data");
      }
      const data = await response.json();
      setcapacityBuget(data);
      setBudgetData(data);
      const totalYTD = data.reduce((sum, item) => sum + item.ytd, 0);
      const uniqueEmpIds = new Set(data.map((item) => item.empid));
      const averageYTD = totalYTD / uniqueEmpIds.size;
      setAverageYTD(averageYTD);
    } catch (error) {
      console.error("Error fetching budget history data:", error);
    }
  };
  useEffect(() => {
    fetchForecast();
  }, [selectedYear, selectedTeam, token]);
  const [capacityData, setCapacityData] = useState([]);
  const navigate = useNavigate();
  useEffect(() => {
    const filteredEmployees = usersList?.filter(
      (user) => user?.STM_Team == selectedId
    );
    setFilteredEmployeeList(
      filteredEmployees?.map((item) => `'${item["EM_ID"]}'`).join(",")
    );
  }, [selectedId]);
  const currentMonth = currentDate.getMonth() + 1;
  const previousMonth = currentMonth === 1 ? 12 : currentMonth - 1; // If Jan, go to Dec
  const twoMonthsAgo =
    currentMonth <= 2 ? 12 + (currentMonth - 2) : currentMonth - 2; // Two months before current month
  const getCapacityByUserAndMonth = (user, monthNumber) => {
    const userCapacity = capacityData.find(
      (data) =>
        data.AssginedTo === user.EM_ID &&
        parseInt(data.CreatedMonth) === monthNumber
    );
    return userCapacity ? parseInt(userCapacity.TotalTime) || 0 : 0;
  };
  const fetchControlData = async () => {
    if(!selectedYear){
      return;
    }
    try {
      const response = await fetch(
        `${taskApiUrl}/GetCapacityUtilisation?CreatedYear=${selectedYear}&AssginedToEmids=${filteredEmployeeList}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response?.status === 401) {
        navigate("/");
      }
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();
      setControlData(data);

      const currentMonth = new Date().getMonth() + 1;
      console.log("Current Month:", currentMonth);

      const mtdTotalTime = data
        .filter((item) => parseInt(item.CreatedMonth) === currentMonth)
        .reduce((sum, item) => {
          const totalTime = Number(item.TotalTime);
          return sum + (isNaN(totalTime) ? 0 : totalTime);
        }, 0);

      console.log("MTD Total Time:", mtdTotalTime);
      const activeTeams = teamNames?.filter((team) => team.status === true);
      const totalEmployees = activeTeams.length;

      console.log("Total Employees:", totalEmployees);
      const averageMTD = totalEmployees > 0 ? mtdTotalTime / totalEmployees : 0;
      const percentageMTD = averageMTD;
      setMTD(percentageMTD.toFixed(2));
    } catch (error) {
      console.error("Error fetching control data:", error);
    }
  };

  const calculateUtilizationMTD = (user) => {
    const actualMTD = getMTDForUser(user);
    const budgetedMTD = getBudgetedMtdForUser(user);
    return budgetedMTD > 0
      ? ((actualMTD / budgetedMTD) * 100).toFixed(2)
      : "0.00";
  };

  const calculateAverageMTD = () => {
    const usersInTeam = usersList?.filter(
      (user) => user.STM_Team === selectedId
    );
    if (usersInTeam?.length === 0) return "0.00";

    const totalMTDUtilization = usersInTeam?.reduce(
      (sum, user) => sum + Number(calculateUtilizationMTD(user)),
      0
    );
    const averageMTD = (totalMTDUtilization / usersInTeam?.length).toFixed(2);

    return averageMTD;
  };

  const averageMTD = calculateAverageMTD();

  const [actualValues, setactualValues] = useState([]);

  useEffect(() => {
    const totalTimes = Array(12).fill(0);
    const counts = Array(12).fill(0);

    controlData.forEach((entry) => {
      const month = parseInt(entry.CreatedMonth) - 1;

      if (month >= 0 && month < 12 && entry.TotalTime) {
        const totalTime = parseInt(entry.TotalTime);
        totalTimes[month] += totalTime;
        counts[month] += 1;
      }
    });

    const averages = totalTimes.map((total, index) =>
      counts[index] === 0 ? 0 : Math.round(total / counts[index])
    );

    setactualValues(averages);
  }, [controlData]);

  useEffect(() => {
    fetchControlData();
  }, [selectedYear, filteredEmployeeList, token]);

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 4,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const handlePrev = () => {
    sliderRef.current.slickPrev();
  };
  const handleNext = () => {
    sliderRef.current.slickNext();
  };
  const [avgActual, setAvgActual] = useState(null);
  const [avgMonthly, setAvgMonthly] = useState(null);

  useEffect(() => {
    updateCircleData();
  }, [averageMonthlyValues, actualValues]);
  const updateCircleData = () => {
    const updatedCircleData = circleData.map((data, index) => ({
      ...data,
      budgeted: (averageMonthlyValues && averageMonthlyValues[index]) || 0,
      actual:
        actualValues &&
        actualValues[index] !== undefined &&
        actualValues[index] !== null
          ? actualValues[index]
          : data.actual || 0,
    }));
    const totalActual = updatedCircleData.reduce(
      (sum, item) => sum + (item.actual || 0),
      0
    );
    const totalBudgeted = updatedCircleData.reduce(
      (sum, item) => sum + (item.budgeted || 0),
      0
    );
    setAvgActual(totalActual);
    setAvgMonthly(totalBudgeted);
    setCircleData(updatedCircleData);
  };

  const outerStyles = {
    path: {
      stroke: "rgba(221, 221, 221, 1)",
      strokeLinecap: "square",
    },
    trail: {
      stroke: "white",
      strokeLinecap: "square",
    },
  };
  const innerStyles = {
    path: {
      stroke: "#50145A",
      strokeLinecap: "square",
    },
    trail: {
      stroke: "white",
      strokeLinecap: "square",
    },
  };
  const [circleData, setCircleData] = useState([
    { month: "Jan", budgeted: 0, actual: 0 },
    { month: "Feb", budgeted: 0, actual: 0 },
    { month: "Mar", budgeted: 0, actual: 0 },
    { month: "April", budgeted: 0, actual: 0 },
    { month: "May", budgeted: 0, actual: 0 },
    { month: "June", budgeted: 0, actual: 0 },
    { month: "July", budgeted: 0, actual: 0 },
    { month: "Aug", budgeted: 0, actual: 0 },
    { month: "Sep", budgeted: 0, actual: 0 },
    { month: "Oct", budgeted: 0, actual: 0 },
    { month: "Nov", budgeted: 0, actual: 0 },
    { month: "Dec", budgeted: 0, actual: 0 },
  ]);

  useEffect(() => {
    if (actualValues.length === 12 && averageMonthlyValues.length === 12) {
      const avgActualValue = Math.round(
        actualValues.reduce((sum, value) => sum + value, 0) /
          actualValues.length
      );
      const avgMonthlyValue = Math.round(
        averageMonthlyValues.reduce((sum, value) => sum + value, 0) /
          averageMonthlyValues.length
      );

      setAvgActual(avgActualValue);
      setAvgMonthly(avgMonthlyValue);
    }
  }, [actualValues, averageMonthlyValues]);
  useEffect(() => {
    updateCircleData();
  }, [actualValues, averageMonthlyValues]);

  const handleClick = () => {
    navigate("/Capacity2"); 
  };

  return (
    <div>
      {" "}
      <div className="graph_main_div">
        <div className="graph-card">
          <div className="m_f">
            <div className="m_f_h" onClick={handleClick} style={{ cursor: "pointer" }}>Capacity Utilization</div>
            <div className="m_f_drop_down">
              <div className="m_f_drop_down-new">
                <Select
                  labelId="year-select-label"
                  value={selectedYear}
                  onChange={handleChange}
                  displayEmpty
                  fullWidth
                >
                  <MenuItem value="" disabled>
                    Select Year
                  </MenuItem>
                  {years.map((year) => (
                    <MenuItem key={year} value={year}>
                      {year}
                    </MenuItem>
                  ))}
                </Select>
               {userData?.[0]?.DR_Name!="Consultant"&& <Select
                  fullWidth
                  value={selectedTeam}
                  onChange={handleTeamChange}
                  displayEmpty
                >
                  <MenuItem value="">Teams</MenuItem>
                  {teamsData[0]
                    ?.filter(
                      (team) => team?.status === true && team?.type === "T"
                    )
                    ?.map((data) => (
                      <MenuItem key={data.id} value={data.name}>
                        {data.name}
                      </MenuItem>
                    ))}
                </Select>}
              </div>
            </div>
          </div>
          <div className="m_f_s_h">
            {avgMonthly} <span className="s-m-c-p">/ </span>
            <span className="s-m-350"> {avgActual}</span>
          </div>
          <div className="slider-wrapper">
            <FontAwesomeIcon
              icon={faCaretLeft}
              className="arrow-left"
              onClick={handlePrev}
            />

            <Slider {...settings} ref={sliderRef}>
              {circleData.map((data, index) => (
                <div
                  key={index}
                  className={`circle-data circle-data-h-${index + 1}`}
                >
                  <div className="skill">
                    <div className="container">
                      <div style={{ width: "100px" }}>
                        <div
                          style={{
                            position: "relative",
                            width: "152px",
                            height: "110px",
                          }}
                        >
                          <CircularProgressbar
                            value={Math.abs(data.budgeted)}
                            className="circle-1"
                            styles={outerStyles}
                          />

                          <div
                            style={{
                              position: "absolute",
                              top: "49%",
                              left: "47%",
                              transform: "translate(-50%, -50%)",
                              width: "80%",
                              height: "80%",
                            }}
                          >
                            <CircularProgressbar
                              value={Math.abs(data.actual) / 10}
                              className="circle-2"
                              styles={innerStyles}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="circle-data-info">
                    <div className="mon">{data.month}</div>
                    <div className="act">Actual: {data.actual}</div>
                    <div className="bug">Budgeted: {data.budgeted}</div>
                  </div>
                </div>
              ))}
            </Slider>

            <FontAwesomeIcon
              icon={faCaretRight}
              className="arrow-right"
              onClick={handleNext}
            />
          </div>
          <div className="gc-dash-footer">
            <div className="gc-dash-footer-data">
              Capacity Utilization for{" "}
              <spam className="gc-dash-footer-spam">
                {" "}
                YTD: {averageYTD.toFixed(2)}% MTD: {mtd}%
              </spam>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MultipleCircularProgress;
