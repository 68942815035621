import React, { useContext, useEffect, useState, useRef } from "react";
import "../Styles/WeekelyTimesheet2.css";
import "../Styles/WeekelyTimesheet1.css";
import "../Styles/ContentWeek.css";
import "../Styles/Activitywise2.css";
import "../Styles/Userwise2.css";
import { UserContext } from "../context/UserContext";
import { AuthContext } from "../context/AuthContext";
import { CircularProgress } from "@mui/material";
import * as XLSX from "xlsx";
import "../Styles/Footerweek.css";
import { Info } from '@mui/icons-material';
import {
  clientApiUrl,
  taskApiUrl,
  notificationApiUrl,
  userApiUrl,
} from "../utils/GetUrl";
import ToastComponent from "./ToastComponent";
function ActivityWise2({
  setDownloadFile,
  downloadFile,
  selectedDate,
  selectedEmployees,
  setProcessedData,
  selectedTeams,
  teams,
  setSelectedTeams,
  timeCode,
  selectedIds,
  selectedCostCenters,
  selectedTimeCode,
}) {
  const [totalTime, setTotalTime] = useState(0);
  const [difference, setDifference] = useState(0);
  const { teamsData, setTeamsData, teamNames, setTeamNames, fetchTeams } =
    useContext(UserContext);
  const [userReport, setUserReport] = useState([]);
  const [timeCodes, setTimeCodes] = useState([]);
  const { token, logout } = useContext(AuthContext);
  const toastRef = useRef();
  const today = new Date();
  const defaultSelectedDate = selectedDate ? new Date(selectedDate) : today;
  const [loading, setLoading] = useState(false);

  const getStartDate = (dateString) => {
    const date = dateString ? new Date(dateString) : new Date();
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    return `${year}-${String(month).padStart(2, "0")}-01`;
  };

  const getEndDate = (dateString) => {
    const date = dateString ? new Date(dateString) : new Date();
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const lastDay = new Date(year, month, 0).getDate();
    return `${year}-${String(month).padStart(2, "0")}-${String(
      lastDay
    ).padStart(2, "0")}`;
  };

  const formatSelectedEmployees = (selected) => {
    const emIds = selected?.map((item) => `'${item["EM_ID"]}'`);
    return emIds?.join(",");
  };

  useEffect(() => {
    formatSelectedEmployees(selectedEmployees);
  }, [selectedEmployees, selectedTeams]);

  useEffect(() => {
    if (timeCode) {
      setTimeCodes([
        ...new Set(timeCode.map((code) => code.AL_SalseforceCode)),
      ]);
    }
  }, [timeCode]);


  const fetchUserWise = async () => {
    setLoading(true); // Start loading
    const chunkSize = 10;

    const fetchInChunks = async (startIndex) => {
      const endIndex = Math.min(
        startIndex + chunkSize,
        selectedEmployees.length
      );
      const chunk = selectedEmployees.slice(startIndex, endIndex);

      try {
        const response = await fetch(
          `${taskApiUrl}/GetAllTaskReport?` +
            `DateRange1=${getStartDate(selectedDate)}` +
            `&DateRange2=${getEndDate(selectedDate)}` +
            `&AssginedToEmids=${formatSelectedEmployees(chunk)}` +
            `${selectedIds ? `&DepartmentId=${selectedIds}` : ""}` +
            `${
              selectedCostCenters ? `&CostCenters=${selectedCostCenters}` : ""
            }` +
            `&ActivityWiseReport=true`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (response.status === 401) {
          toastRef.current.showToast(
            "Unauthorised access. Please login again.",
            "error"
          );
          await logout();
          return;
        }

        const data = await response.json();
        setUserReport((prevData) => [...prevData, ...data]);

        if (endIndex < selectedEmployees.length) {
          await fetchInChunks(endIndex);
        }
      } catch (e) {
        //console.log(e);
      }
    };

    if (selectedEmployees.length > 0) {
      setUserReport([]);
      await fetchInChunks(0);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchUserWise();
  }, [selectedDate, selectedEmployees, selectedIds, selectedTimeCode]);

  const processUserReport = (userReport) => {
    return selectedEmployees?.map((employee) => {
      const emId = employee["EM_ID"];
      const emName = employee["EM_FirstName"]+' '+employee["EM_LastName"];
      const emTeam = teamNames?.filter((t) => t.id == employee["STM_Team"])?.[0]
        ?.name;

      const employeeReport = userReport.find(
        (report) => report.AssginedTo === emId
      );

      const timeCodeMap = {};
      let emViewForceTime = 0;
      let emTotalTime = 0;

      if (employeeReport) {
        emViewForceTime = employeeReport.TotalRegisterTime || 0;

        employeeReport.taskReportResponse.forEach((task) => {
          const code = task.SalesforceCode;
          const time = parseFloat(task.TotalTime) || 0;
          timeCodeMap[code] = (timeCodeMap[code] || 0) + time;
        });
      }

      emTotalTime = Object.values(timeCodeMap).reduce(
        (total, time) => total + time,
        0
      );
      const emDiff = emTotalTime - emViewForceTime;

      return {
        emId,
        emName,
        emTeam,
        timeData: timeCodes.map((code) => timeCodeMap[code] || 0),
        emTotalTime,
        emViewForceTime,
        emDiff,
      };
    });
  };

  const transformData = (data) => {
    return data.map((item) => {
      const transformedItem = {
        emId: item.emId,
        emName: item.emName,
        emTeam: item.emTeam,
        emTotalTime: item.emTotalTime,
        emViewForceTime: item.emViewForceTime,
        emDiff: item.emDiff,
      };

      item.timeData.forEach((time, index) => {
        transformedItem[timeCodes[index]] = time;
      });

      return transformedItem;
    });
  };

  const downloadExcel = (data) => {
    const transformedData = transformData(data);
    const worksheet = XLSX.utils.json_to_sheet(transformedData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Report");
    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    const blob = new Blob([excelBuffer], { type: "application/octet-stream" });
    const link = document.createElement("a");
    const url = URL.createObjectURL(blob);
    link.href = url;
    link.download = "activity_wise_report.xlsx";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const processedUserReport = processUserReport(userReport);
  //console.log(processedUserReport);

  useEffect(() => {
    if (downloadFile === true) {
      downloadExcel(processedUserReport);
      setDownloadFile(false);
    }
  }, [downloadFile]);

  const getRowStyle = (index) => ({
    backgroundColor: index % 2 === 0 ? "#f0f0f0" : "white",
  });
  const tableContainerRef = useRef(null);
  const handleMouseScroll = () => {
    const tableContainer = tableContainerRef.current;

    let isDown = false;
    let startX;
    let scrollLeft;

    const mouseDownHandler = (e) => {
      isDown = true;
      tableContainer.classList.add('active');
      startX = e.pageX - tableContainer.offsetLeft;
      scrollLeft = tableContainer.scrollLeft;
    };

    const mouseLeaveHandler = () => {
      isDown = false;
      tableContainer.classList.remove('active');
    };

    const mouseUpHandler = () => {
      isDown = false;
      tableContainer.classList.remove('active');
    };

    const mouseMoveHandler = (e) => {
      if (!isDown) return;
      e.preventDefault();
      const x = e.pageX - tableContainer.offsetLeft;
      const walk = (x - startX) * 2; 
      tableContainer.scrollLeft = scrollLeft - walk;
    };

    tableContainer.addEventListener('mousedown', mouseDownHandler);
    tableContainer.addEventListener('mouseleave', mouseLeaveHandler);
    tableContainer.addEventListener('mouseup', mouseUpHandler);
    tableContainer.addEventListener('mousemove', mouseMoveHandler);
    return () => {
      tableContainer.removeEventListener('mousedown', mouseDownHandler);
      tableContainer.removeEventListener('mouseleave', mouseLeaveHandler);
      tableContainer.removeEventListener('mouseup', mouseUpHandler);
      tableContainer.removeEventListener('mousemove', mouseMoveHandler);
    };
  };

  const [isInfoMenuOpen, setIsInfoMenuOpen] = useState(false);
  const infoMenuRef = useRef(null);


  const toggleInfoMenu = () => {
      setIsInfoMenuOpen(!isInfoMenuOpen);
    };
  
    const closeInfoMenu = () => {
      setIsInfoMenuOpen(false);
    };

     // Close the menu when clicking outside or scrolling
useEffect(() => {
  const handleClickOutside = (event) => {
    if (infoMenuRef.current && !infoMenuRef.current.contains(event.target)) {
      closeInfoMenu();
    }
  };

  const handleScroll = () => {
    closeInfoMenu();
  };

  document.addEventListener('mousedown', handleClickOutside);
  window.addEventListener('scroll', handleScroll);

  return () => {
    document.removeEventListener('mousedown', handleClickOutside);
    window.removeEventListener('scroll', handleScroll);
  };
}, []);


const tttime = (processedUserReport?.reduce((acc, curr) => acc + curr.emTotalTime, 0));
      const ttdiff = (processedUserReport?.reduce((acc, curr) => acc + curr.emDiff, 0));
      console.log(tttime, ttdiff)

useEffect(()=>{
  if(!loading){
    setDifference(ttdiff?ttdiff.toFixed(2):0);
    setTotalTime(tttime?tttime.toFixed(2):0);
  } 
},[loading]);

  return (


    <div className="main-newcontent">
      <ToastComponent ref={toastRef} timeout={4000} />

      {/* Show the CircularProgress when loading is true */}
      {loading && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "20px",
          }}
        >
          <CircularProgress style={{ color: "purple" }} width={40} />
        </div>
      )}

      {!loading && (
        <div className="Content-week22">
          <div style={{ display: "flex", overflow: "hidden" }}>
            {/* Fixed Columns on the Left */}
            <table
              className="user-report-table"
              style={{
                flexShrink: 0,
                minWidth: "200px",
                position: "relative",
                background: "white",
              }}
            >
              <thead>
                <tr>
                  <th
                    className="content-user-2"
                    style={{
                      position: "sticky",
                      left: 0,
                      zIndex: 2,
                      background: "white",
                      width: "100px",
                      height: "60px",
                      background: "rgba(230, 230, 230, 1)",
                      color: "rgba(24, 5, 34, 1)",
                      fontWeight: 600,
                    }}
                  >
                    EMPLOYEE
                  </th>
                  <th
                    className="content-user-line"
                    style={{
                      position: "sticky",
                      left: "100px",
                      zIndex: 2,
                      background: "white",
                      width: "100px",
                      height: "60px",
                      background: "rgba(230, 230, 230, 1)",
                      color: "rgba(24, 5, 34, 1)",
                      fontWeight: 600,
                    }}
                  >
                    TEAM
                  </th>
                </tr>
              </thead>
              <tbody>
                {processedUserReport?.map(({ emId, emName, emTeam }, index) => (
                  <tr
                    key={emId}
                    style={{
                      backgroundColor:
                        index % 2 === 0 ? "rgba(241, 241, 241, 1)" : "white",
                    }}
                  >
                    <td
                      className="content-user-2"
                      style={{
                        position: "sticky",
                        left: 0,
                        width: "260px",
                        display: "flex",
                      }}
                    >
                      {emName}
                    </td>
                    <td
                      className="content-user-line"
                      style={{
                        position: "sticky",
                        left: "100px",
                        width: "260px",
                        textAlign: "left",
                      }}
                    >
                      {emTeam}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>

            {/* Scrollable Middle Section */}
            <div ref={tableContainerRef}
       style={{
        overflow: "scroll", 
        flexGrow: 1,
        minWidth: "600px",
        
        
      }}
      onMouseEnter={handleMouseScroll}>              <table
                className="user-report-table"
                style={{ minWidth: "600px", tableLayout: "fixed" }}
              >
                <thead>
                  <tr>
                    {timeCodes?.map((code, index) => (
                      <th
                        key={index}
                        className="table-title-twos"
                        style={{
                          minWidth: "280px",
                          textAlign: "center",
                          background: "white",
                          height: "60px",
                        }}
                      >
                        <div className="table-title-actuvity-content">
                          {code}
                        </div>
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {processedUserReport?.map(({ emId, timeData }, index) => (
                    <tr key={emId} style={getRowStyle(index)}>
                      {timeData?.map((time, timeIndex) => (
                        <td
                          key={timeIndex}
                          style={{
                            minWidth: "280px",
                            textOverflow: "ellipsis",
                            overflow: "hidden",
                            whiteSpace: "nowrap",
                            height: "60px",
                          }}
                          className="content-user-date"
                        >
                          {time ? time : "0"}
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>

            {/* Fixed Columns on the Right */}
            <table
              className="user-report-table"
              style={{
                flexShrink: 0,
                minWidth: "300px",
                position: "relative",
                background: "white",
              }}
            >
              <thead>
                <tr>
                  <th
                    className="content-user-before"
                    style={{
                      position: "sticky",
                      right: "200px",
                      zIndex: 2,
                      background: "white",
                      width: "100px",
                      height: "60px",
                      background: "rgba(230, 230, 230, 1)",
                      color: "rgba(24, 5, 34, 1)",
                      fontWeight: 600,
                      paddingRight: "20px",
                    }}
                  >
                    TOTAL
                  </th>
                  <th
                    className="content-user"
                    style={{
                      position: "sticky",
                      right: "100px",
                      zIndex: 2,
                      background: "white",
                      width: "100px",
                      height: "60px",
                      background: "rgba(230, 230, 230, 1)",
                      color: "rgba(24, 5, 34, 1)",
                      fontWeight: 600,
                    }}
                  >
                    VIEW FORCE
                  </th>
                  <th
                    className="content-user"
                    style={{
                      position: "sticky",
                      right: 0,
                      zIndex: 2,
                      background: "white",
                      width: "100px",
                      height: "60px",
                      background: "rgba(230, 230, 230, 1)",
                      color: "rgba(24, 5, 34, 1)",
                      fontWeight: 600,
                    }}
                  >
                    DIFFERENCE
                  </th>
                </tr>
              </thead>
              <tbody>
                {processedUserReport?.map(
                  ({ emId, emTotalTime, emViewForceTime, emDiff }, index) => (
                    <tr
                      key={emId}
                      style={{
                        backgroundColor:
                          index % 2 === 0 ? "rgba(241, 241, 241, 1)" : "white",
                      }}
                    >
                      <td
                        className="content-user-before"
                        style={{
                          position: "sticky",
                          right: "200px",
                          width: "100px",
                        }}
                      >
                        {emTotalTime}
                      </td>
                      <td
                        className="content-user"
                        style={{
                          position: "sticky",
                          right: "100px",
                          width: "100px",
                        }}
                      >
                        {emViewForceTime}
                      </td>
                      <td
                        className="content-user"
                        style={{ position: "sticky", right: 0, width: "100px" }}
                      >
                        {emDiff}
                      </td>
                    </tr>
                  )
                )}
              </tbody>
            </table>
          </div>
        </div>
      )}
         <div className='footer-container-w'>
        <div className='footer-design'>
            
            <div className='footer-title'>TOTAL TIME

            <button onClick={toggleInfoMenu}>
                      <Info  className='icon-footer' weight='duotone' color='gray' />
                      
                    </button>
                    </div>
                    {isInfoMenuOpen && (

                      <div className="overlay-footer"  onClick={closeInfoMenu} ref={infoMenuRef}>
                        <div className="info-menu-footer">
                          <ul>
                            <li>Total Time : {totalTime}</li>
                            <li>Total Difference : {difference}</li>
                            
                          </ul>
                        </div>
                      </div>
                      )}

            
        </div>
      
    </div>
    </div>
  );
}

export default ActivityWise2;
