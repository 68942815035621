import React, { useContext, useEffect, useState } from "react";
import NavChild from "../components/NavChild";
import TimeCodeCard from "../components/TimeCodeCard";
import "../Styles/TimeCodeCard.css";
import NavSub from "../components/NavSub";
import { AuthContext } from "../context/AuthContext";
import CancelIcon from "@mui/icons-material/Cancel";
import {
  clientApiUrl,
  taskApiUrl,
  notificationApiUrl,
  userApiUrl,
} from "../utils/GetUrl";
import { CaretDown } from "@phosphor-icons/react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { Height } from "@mui/icons-material";
import { cleanDigitSectionValue } from "@mui/x-date-pickers/internals/hooks/useField/useField.utils";
import { UserContext } from "../context/UserContext";
import { useNavigate } from "react-router-dom";
function TimeCode() {
  const { token, logout } = useContext(AuthContext);
  const { usersList } = useContext(UserContext);
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
  const [allowedDate, setAllowedDate ] = useState();
  const [updatedDate, setUpdatedDate ] = useState();
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    maxWidth: 1100,
    width: "70%",
    height: 500,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const [lockData, setLockData] = useState([]);
  const { userData } = useContext(AuthContext);


  const initialMonthlyData={
    Vouchers: {
      Jan: true,
      Feb: true,
      Mar: true,
      Apr: true,
      May: true,
      Jun: true,
      Jul: true,
      Aug: true,
      Sep: true,
      Oct: true,
      Nov: true,
      Dec: true,
    },
    "Reporting Activities": {
      Jan: true,
      Feb: true,
      Mar: true,
      Apr: true,
      May: true,
      Jun: true,
      Jul: true,
      Aug: true,
      Sep: true,
      Oct: true,
      Nov: true,
      Dec: true,
    },
    "Control Point": {
      Jan: true,
      Feb: true,
      Mar: true,
      Apr: true,
      May: true,
      Jun: true,
      Jul: true,
      Aug: true,
      Sep: true,
      Oct: true,
      Nov: true,
      Dec: true,
    },
    ViewForceData: {
      Jan: true,
      Feb: true,
      Mar: true,
      Apr: true,
      May: true,
      Jun: true,
      Jul: true,
      Aug: true,
      Sep: true,
      Oct: true,
      Nov: true,
      Dec: true,
    },
  }
  const [monthlyData, setMonthlyData] = useState(initialMonthlyData);
  const navigate = useNavigate();
  const getAllActivityLock = async () => {
    if(!token){
      return;
    }
    const response = await fetch(
      `${taskApiUrl}/GetAllActivityLock?PeriodDateYear=${currentYear}&GetHistory=false`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );
    if (response?.status === 401) {
      navigate("/");
    }
    const data = await response.json();
    setLockData(data);
  };

  useEffect(() => {
    setMonthlyData(initialMonthlyData);
    // Fetch and set new data for the selected year
    getAllActivityLock(currentYear);
}, [currentYear]);

  useEffect(() => {
    console.log('triggered')
    const updatedMonthlyData = JSON.parse(JSON.stringify(monthlyData));
    console.log(monthlyData);
    console.log('This year lock data ' , lockData);
    lockData.forEach((item) => {
      const monthIndex = new Date(item.PeriodDate).getMonth();
      const monthName = months[monthIndex];
      if (item.Type === "Vouchers") {
        updatedMonthlyData["Vouchers"][monthName] = false;
      } else if (item.Type === "Control Point") {
        updatedMonthlyData["Control Point"][monthName] = false;
      } else if (item.Type === "Reporting Activities") {
        updatedMonthlyData["Reporting Activities"][monthName] = false;
      } else if (item.Type === "ViewForceData") {
        updatedMonthlyData["ViewForceData"][monthName] = false;
      }
      console.log(updatedMonthlyData)
    });

    setMonthlyData(updatedMonthlyData);
  }, [lockData]);

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [pendingAction, setPendingAction] = useState(null);
  const handleCheckboxChange = (activityType, month, isChecked) => {
    setPendingAction({ activityType, month, isChecked });
    setShowDeleteModal(true);
  };

  const handleConfirmChange = async () => {
    const { activityType, month, isChecked } = pendingAction;

    const updatedMonthlyData = {
      ...monthlyData,
      [activityType]: {
        ...monthlyData[activityType],
        [month]: !isChecked,
      },
    };
    setMonthlyData(updatedMonthlyData);

    if (isChecked) {
      // Add lock
      const response = await fetch(`${taskApiUrl}/AddActivityLock`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          Type: [activityType],
          CreateByEmid: userData?.[0]?.EM_ID,
          PeriodDate: createFormattedDate(month),
        }),
      });
      if (response?.status === 401) {
        // navigate('/');
      }
      const data = await response.json();
      if (data) {
            setMonthlyData(initialMonthlyData);
        getAllActivityLock();
      }
    } else {
      // Remove lock
      const id = lockData?.filter(
        (item) =>
          item.Type === activityType &&
          getMonthNameFromDate(item.PeriodDate) === month
      )?.[0]?.Id;
      const response = await fetch(
        `${taskApiUrl}/DeleteActivityLock?id=${id}`,
        {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      if (response?.status === 401) {
        // navigate('/');
      }
      const data = await response.json();
      if (data) {
            setMonthlyData(initialMonthlyData);

        getAllActivityLock();
      }
    }

    setShowDeleteModal(false); // Close the modal after the action is confirmed
  };

  const handleCancelChange = () => {
    setShowUpdateModal(false);
    setShowDeleteModal(false); // Close the modal without making changes
  };

  function createFormattedDate(month) {
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const year = currentYear;
    const day = 1;
    const monthIndex = months.indexOf(month) + 1; // getMonth is zero-based, so we add 1

    if (monthIndex === 0) {
      throw new Error("Invalid month name");
    }

    // Pad month and day with leading zeros if necessary
    const monthString = monthIndex.toString().padStart(2, "0");
    const dayString = day.toString().padStart(2, "0");

    return `${year}-${monthString}-${dayString}`;
  }

  function getMonthNameFromDate(dateString) {
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const date = new Date(dateString);
    const monthIndex = date.getMonth();
    return months[monthIndex];
  }

  const [trueData, setTrue] = useState([]);
  const getAllActivityLockTrue = async () => {
    const response = await fetch(
      `${taskApiUrl}/GetAllActivityLock?PeriodDateYear=${new Date().getFullYear()}&GetHistory=true`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );
    if (response?.status === 401) {
      navigate("/");
    }
    const data = await response.json();
    //console.log(data);
    setTrue(data);
  };
 

  useEffect(() => {
    if (open) {
      getAllActivityLockTrue();
    }
  }, [open]);

  const startYear = 2024;
  const endYear = 2099;
  const years = [];

  for (let year = startYear; year <= endYear; year++) {
    years.push(year);
  }

  const handleYearChange = (event) => {
    setCurrentYear(event.target.value);
  };
  

  const checkIfDownloadable = async () => {
    try {
      const response = await fetch(
        `${taskApiUrl}/GetFileDownloadAction`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.status === 401) {
      
        await logout();
        return;
      }
      if (response.ok) {
        const data = await response.json();
       if(data){

        const validationDate = new Date(data?.[0]?.ValidationDate);
       
        // Find the maximum of the two dates
  
        // Compare maxSelectedDate with validationDate
        if (validationDate) {
          setAllowedDate(validationDate);
        } 
      }
     
       
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(()=>{
    checkIfDownloadable();
  },[])

  const handleUpdateDownloadTime =async ()=>{
    console.log('Clicked')
    try {
      const response = await fetch(
        `${taskApiUrl}/UpdateFileDownloadAction`,
        {
          method: "PUT",
          headers: {
            Authorization: `Bearer ${token}`,
          },
          body:JSON.stringify({
            "Allow":"timeSheet",
            "UserEmid":userData?.[0]?.EM_ID,
            "ValidationDate":(updatedDate),
            "Id":"1"
          })
        }
      );
      if (response.status === 401) {
      
        await logout();
        return;
      }
      if (response.ok) {
        const data = await response.json();
       if(data){
        setShowUpdateModal(false);
        checkIfDownloadable();
        
      }
     
       
      }
    } catch (error) {
      console.log(error);
    }
  
  }

  return (
    <div>
      <NavSub />
      {/* <MasterTimeCodeCard
        months={months}
        onCheckboxChange={handleMasterCheckboxChange}
      /> */}
      <div className="flex align-center justify-between">
      <div className="his-pop-up-tc">
        <div className="his-pop-up-tc-head">HISTORY</div>
        <CaretDown
          size={16}
          color="#3d025f"
          weight="fill"
          onClick={handleOpen}
        />
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={open}
          onClose={handleClose}
          closeAfterTransition
          slots={{ backdrop: Backdrop }}
          slotProps={{
            backdrop: {
              timeout: 500,
            },
          }}
        >
          <Fade in={open}>
            <Box sx={style}>
              <div className="poc-card-close">
                <div className="his-pop-up-tc-head">HISTORY</div>
                <CancelIcon
                  onClick={handleClose}
                  style={{ cursor: "pointer" }}
                />
              </div>
              <div className={`scroll-container-pp-tc`}>
                <div className="scroll-container-pp-tc-maintext">
                  Voucher Entry
                </div>
                <hr />
                {trueData
                  ?.filter((i) => i.Type === "Vouchers")
                  .map((item) => (
                    <div
                      className={`scroll-container-pp-tc-text`}
                      key={item?.Id}
                    >
                      {
                        usersList?.filter(
                          (user) => user?.EM_ID === item.CreateByEmid
                        )?.[0]?.EM_FirstName
                      }{" "}
                      Updated on {item.PeriodDate}
                    </div>
                  ))}
                <div></div>
                <div></div>
                <div className="scroll-container-pp-tc-maintext">
                  STR Activities
                </div>
                <hr />
                {trueData
                  ?.filter((i) => i.Type === "Control Point")
                  .map((item) => (
                    <div
                      className={`scroll-container-pp-tc-text`}
                      key={item?.Id}
                    >
                      {
                        usersList?.filter(
                          (user) => user?.EM_ID === item.CreateByEmid
                        )?.[0]?.EM_FirstName
                      }{" "}
                      Updated on {item.PeriodDate}
                    </div>
                  ))}
                <div></div>
                <div></div>
                <div className="scroll-container-pp-tc-maintext">
                  Reporting Activity
                </div>
                <hr />
                {trueData
                  ?.filter((i) => i.Type === "Reporting Activities")
                  .map((item) => (
                    <div
                      className={`scroll-container-pp-tc-text`}
                      key={item?.Id}
                    >
                      {
                        usersList?.filter(
                          (user) => user?.EM_ID === item.CreateByEmid
                        )?.[0]?.EM_FirstName
                      }{" "}
                      Updated on {item.PeriodDate}
                    </div>
                  ))}
                <div></div>
                <div></div>
                <div className="scroll-container-pp-tc-maintext">
                  ViewFOrce Activity
                </div>
                <hr />
                {trueData
                  ?.filter((i) => i.Type === "ViewForceData")
                  .map((item) => (
                    <div
                      className={`scroll-container-pp-tc-text`}
                      key={item?.Id}
                    >
                      {
                        usersList?.filter(
                          (user) => user?.EM_ID === item.CreateByEmid
                        )?.[0]?.EM_FirstName
                      }{" "}
                      Updated on {item.PeriodDate}
                    </div>
                  ))}
              </div>
            </Box>
          </Fade>
        </Modal>
      </div>
      <div className='self-center'>
      <select value={currentYear} onChange={handleYearChange} className='p-4 mx-2' style={{background:'rgba(239, 230, 255, 1)'}}>
        <option value="" disabled>Select Year</option>
        {years.map((year) => (
          <option key={year} value={year}>
            {year}
          </option>
        ))}
      </select>
    </div>   
      </div>
      <div className="TimeCode_Fileds" key={currentYear}>
        <TimeCodeCard
          closeTitle="Period Close"
          reopenTitle="Period Open"
          months={months}
          Title="VOUCHER ENTRY"
          activityType="Vouchers"
          monthlyData={monthlyData}
          onCheckboxChange={handleCheckboxChange}
        />
        <TimeCodeCard
          closeTitle="Period Close"
          reopenTitle="Period Open"
          months={months}
          Title="REPORTING ACTIVITY And ADHOC"
          activityType="Reporting Activities"
          monthlyData={monthlyData}
          onCheckboxChange={handleCheckboxChange}
        />
        <TimeCodeCard
          closeTitle="Period Close"
          reopenTitle="Period Open"
          months={months}
          Title="STR ACTIVITIES"
          activityType="Control Point"
          monthlyData={monthlyData}
          onCheckboxChange={handleCheckboxChange}
        />
        <TimeCodeCard
          closeTitle="View Force Close"
          reopenTitle="View Force Open"
          months={months}
          Title="VIEW FORCE DATA"
          activityType="ViewForceData"
          monthlyData={monthlyData}
          onCheckboxChange={handleCheckboxChange}
        />

        <div style={{
                  
                  width:'95%',
                  margin:'1rem auto'
                }}>
         <div style={{
                  fontSize: "20px",
                  color: "#000",
                  fontWeight: 500,
                }}>Allow Consultants to download Weekly Time sheet Upto: <span style={{color: 'green', fontWeight:'900'}}>{allowedDate?.toISOString()?.slice(0, 10)}</span> </div> 
         <button className="sure_cancel" onClick = {()=>{
          setShowUpdateModal(true);
         }}>Update</button>
        </div>
        {showDeleteModal && (
          <div className="modal-overlay">
            <div className="delete-modal">
              <p
                style={{
                  fontSize: "16px",
                  color: "#000",
                  fontWeight: 500,
                }}
              >
                Are you sure you want to change the status?
              </p>
              <p
                style={{
                  fontSize: "14px",
                  color: "#676767",
                  fontWeight: 400,
                }}
              >
              </p>
              <div className="sure_btns">
                <button className="sure_ok" onClick={handleConfirmChange}>
                  Ok
                </button>
                <button className="sure_cancel" onClick={handleCancelChange}>
                  Cancel
                </button>
              </div>
            </div>
          </div>
        )}
        {showUpdateModal && (
          <div className="modal-overlay">
            <div className="delete-modal">
              <p
                style={{
                  fontSize: "16px",
                  color: "#000",
                  fontWeight: 500,
                }}
              >
                Are you sure you want to change the date range?
              </p>
              <p
                style={{
                  fontSize: "14px",
                  color: "#676767",
                  fontWeight: 400,
                }}
              >
                <input style={{margin:'1rem auto', fontSize:'24px'}}type='date' value={updatedDate} onChange={(e)=>{setUpdatedDate(e.target.value)}} />
              </p>
              <div className="sure_btns">

                <button className="sure_ok" onClick={handleUpdateDownloadTime}>
                  Update
                </button>
                <button className="sure_cancel" onClick={handleCancelChange}>
                  Cancel
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default TimeCode;
